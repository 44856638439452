import { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import leftImg from "../../assets/images/logo.webp";
import arabicBingC from "../../assets/images/arabic_bigc.png";

import Select from "react-select";
import cityLogo from "../../assets/images/vote-logo.webp";
import arabicSmall from "../../assets/images/Arabic-logo_Arb-3.png";
import fb from "../../assets/images/facebook.webp";
import twitter from "../../assets/images/twitter.webp";
import share from "../../assets/images/share.webp";
import { NavLink } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import finalLogo from "../../assets/images/thanks.webp";
import { set, useForm, Controller } from "react-hook-form";
import "./vote.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ServiceBase from "../../apis/Api";
import i18n from "../../i18n";
import { toast, ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory,
  usePrompt,
  useBlocker,
  Routes,
} from "react-router-dom";
import ReactPixel from "react-facebook-pixel";
import ReactSnapPixel from "react-snapchat-pixel";

const advancedMatching = { em: "some@email.com" };
const options = {
  autoConfig: true,
  debug: false,
};
const ageOptions = [
  { title_en: "Below 18", title_ar: "أقل من 18 عاماً" },
  { title_en: "19-25", title_ar: "25-19" },
  { title_en: "26-30", title_ar: "30-26" },
  { title_en: "31-35", title_ar: "35-31" },
  { title_en: "36-40", title_ar: "40-36" },
  { title_en: "41-45", title_ar: "45-41" },
  { title_en: "46-55", title_ar: "55-46" },
  { title_en: "55-65", title_ar: "65-55" },
  { title_en: "65+", title_ar: "65+" },
];
const loyalityOption = [
{title_en:"Yes",title_ar:"نعم",value:true},
{title_en:"No",title_ar:"لا",value:false}
];
// const residenceOptions = [
//   { title_en: "New Cairo", title_ar:"New Cairo" },
//   { title_en: "Madinaty", title_ar:"Madinaty" },
//   { title_en: "El Sherouk", title_ar:"El Sherouk" },
//   { title_en: "Heliopolis", title_ar:"Heliopolis" },
//   { title_en: "Nasr City", title_ar:"Nasr City" },
//   { title_en: "Maadi", title_ar:"Maadi" },
//   { title_en: "Zamalek", title_ar:"Zamalek" },
//   { title_en: "Downtown", title_ar:"Downtown" },
//   { title_en: "Garden City", title_ar:"Garden City" },
//   { title_en: "Mohandessin", title_ar:"Mohandessin" },
//   { title_en: "Sheikh Zayed", title_ar:"Sheikh Zayed" },
//   { title_en: "6 October", title_ar:"6 October" },
//   { title_en: "Alexandria", title_ar:"Alexandria" },
//   { title_en: "Other (please specify)", title_ar:"Other (please specify)" },
// ];

const residenceOptions = [
  { title_en: "Hazmieh", title_ar:"Hazmieh" },
  { title_en: "Baabda", title_ar:"Baabda" },
  { title_en: "Chiyah ", title_ar:"Chiyah " },
  { title_en: "Beirut", title_ar:"Beirut" },
  { title_en: "Ashrafieh", title_ar:"Ashrafieh" },
  { title_en: "Verdun", title_ar:"Verdun" },
  { title_en: "Other (please specify)", title_ar:"Other (please specify)" },
];
const frequentlyVisitOptions = [
  { title_en: "Daily", title_ar:"Daily" },
  { title_en: "Once or twice a week", title_ar:"Once or twice a week" },
  { title_en: "A couple of times per month", title_ar:"A couple of times per month" },
  { title_en: "Monthly", title_ar:"Monthly" },
  { title_en: "Rarely", title_ar:"Rarely" },
];
const timeSpendOptions = [
  { title_en: "Less than 1 hour", title_ar:"Less than 1 hour" },
  { title_en: "1-3 hours", title_ar:"1-3 hours" },
  { title_en: "3-5 hours", title_ar:"3-5 hours" },
  { title_en: "More than 5 hours", title_ar:"More than 5 hours" },
];
const moneySpendOptions = [
  { title_en: "Less than EGP 1,000", title_ar:"Less than EGP 1,000" },
  { title_en: "EGP 1,000 – EGP 3,000 ", title_ar:"EGP 1,000 – EGP 3,000 " },
  { title_en: "EGP 3,000 – EGP 5,000", title_ar:"EGP 3,000 – EGP 5,000" },
  { title_en: "EGP 5,000 – EGP 7,000", title_ar:"EGP 5,000 – EGP 7,000" },
  { title_en: "More than EGP 7,000", title_ar:"More than EGP 7,000" },
];

const moneySpendOptionsLebanon = [
  { title_en: "Less than LBP 3,000,000", title_ar:"Less than LBP 3,000,000" },
  { title_en: "LBP 3,000,000 – LBP 9,000,000 ", title_ar:"LBP 3,000,000 – LBP 9,000,000 " },
  { title_en: "LBP 9,000,000 – LBP 15,000,000", title_ar:"LBP 9,000,000 – LBP 15,000,000" },
  { title_en: "More than 15,000,000 LBP", title_ar:"More than 15,000,000 LBP" },
];

const Vote = ({ t }) => {
  document.body.classList.remove("home");
  const [step, setStep] = useState({
    step1: false,
    step2: true,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
    step7: false,
    step8: false,
  });
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedAgeOption, setSelectedAgeOption] = useState(null);

  const [catSelected, setCatSelectedOption] = useState(null);
  const [catData, setCatData] = useState(null);
  const [qIndex, setQIndex] = useState(0);
  const [qData, setQData] = useState("");
  const [active, setActive] = useState();
  const [inputText, setInputData] = useState();

  const [selectedCheckbox, setSelectedCheckBox] = useState([]);
  const [activeMultiple, setActiveMultiple] = useState();
  const [inputTextMultiple, setInputDataMultiple] = useState();

  const [questionsList, setQuestions] = useState([]);
  const [compareList, setCompareList] = useState([]);

  const [answerText, setAnswerText] = useState("");
  const [answersList, setAnswersList] = useState({});
  const [itemList, setItemList] = useState([]);
  const [selectedQuestionsList, setSelectedQuestionsList] = useState([]);
  const [catArray, setCatArray] = useState([]);
  const [lang, setLang] = useState("");
  const [mallsList, setMallsList] = useState([]);
  const [ageList, setAgeList] = useState([]);
  const [loyalList, setLoyalList] = useState([]);
  const [residenceList, setResidenceList] = useState([]);
  const [frequentlyVisitList, setFrequentlyVisitList] = useState([]);
  const [timeSpendList, setTimeSpendList] = useState([]);
  const [moneySpendList, setMoneySpendList] = useState([]);
  const [moneySpendListLebanon, setMoneySpendListLebanon] = useState([]);



  const [isBlocking, setIsBlocking] = useState(false);
  const [validateForm, setValidateForm] = useState(false);

  const [residenceValue, setResidenceValue] = useState("");
  const [residenceOther, setResidenceOther] = useState("");

  usePrompt(t("leave_txt"), isBlocking);

  const {
    register,
    getValues,
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const onSubmit = (formData) => {

    if(getValues("residence") === "Other (please specify)"){
       formData.residence = residenceOther;
    };
    ReactPixel.track("CompleteRegistration");
    ReactSnapPixel.track("SIGN_UP");
    // console.log(formData);
    let userData = { ...formData };
    userData.data = catArray;
    // userData.age = selectedAgeOption;
    userData.mall_id = selectedOption.id;

    ServiceBase()
      .post("users", userData)
      .then((res) => {
        setStep({ step7: false, step8: true });
        setIsBlocking(false);
      })
      .catch((error) => {
        for (const item of error.response.data.error) {
          toast.error(item);
        }
      });
  };

  // Function to update list on drop
  const handleDrop = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    var updatedList = [...itemList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setItemList(updatedList);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    setIsBlocking(true);
  };
  const handleChangeAgeList = (value) => {
    setSelectedAgeOption(value.value);
  };
  const changeList = (item, index) => {
    if (item.is_other == 1) {
      setActive("other");
      setInputData(item);
    } else {
      setActive(item);
    }
  };
  useEffect(() => {}, [active]);
  useEffect(() => {}, [activeMultiple]);

  const changeFinalList = () => {
    if (qIndex + 1 === catData.questions.length) {
      setStep({ step4: false, step5: true });
    } else {
      setQIndex(qIndex + 1);
    }
    if (inputText) {
      let answerArray = [];
      const element = {};
      element.option_id = inputText.id;
      answerArray.push(element);
      let data = {
        question_id: qData.id,
        question_title: qData.text_en,
        answers: answerArray,
        answer_text: answerText,
        category_id: catData.id,
      };
      setSelectedQuestionsList([...selectedQuestionsList, data]);
      setActive(null);
    } else {
      let answerArray = [];
      const element = {};
      element.option_id = active.id;
      element.title_en = active.title_en;

      answerArray.push(element);
      let data = {
        question_id: qData.id,
        question_title: qData.text_en,

        answers: answerArray,
        category_id: catData.id,
      };
      setSelectedQuestionsList([...selectedQuestionsList, data]);
      setActive(null);
    }
    setInputData("");
  };
  const changeCheckboxes = (item) => {
    if (item.is_other == 1) {
      setActiveMultiple("other");
      setInputDataMultiple(item);
    } else {
      let index = selectedCheckbox.indexOf(item);
      if (index === -1) {
        setSelectedCheckBox([...selectedCheckbox, item]);
      } else {
        setSelectedCheckBox(selectedCheckbox.filter((id) => id !== item));
      }
      setActiveMultiple(null);
    }
  };
  const changeListCheckBox = () => {
    if (qIndex + 1 === catData.questions.length) {
      setStep({ step4: false, step5: true });
    } else {
      setQIndex(qIndex + 1);
    }

  

    let answerArray = [];
    for (const list of selectedCheckbox) {
      const element = {};
      element.option_id = list.id;
      element.title_en = list.title_en;
      answerArray.push(element);
    }
    if (inputTextMultiple) {
      const element = {};
      element.option_id = inputTextMultiple.id;
      answerArray.push(element);
    }
    let data = {
      question_id: qData.id,
      question_title: qData.text_en,
      answer_text: answerText,
      answers: answerArray,
      // answer_text: answerText,
      category_id: catData.id,
    };
    setSelectedQuestionsList([...selectedQuestionsList, data]);
    setSelectedCheckBox([]);
    setActiveMultiple(null);

    setInputDataMultiple("");

   
  };

  const textAnswerList = () => {
    if (qIndex + 1 === catData.questions.length) {
      setStep({ step4: false, step5: true });
    } else {
      setQIndex(qIndex + 1);
      setAnswerText("");
    }

    let data = {
      question_id: qData.id,
      question_title: qData.text_en,

      answer_text: answerText,
      category_id: catData.id,
    };
    setSelectedQuestionsList([...selectedQuestionsList, data]);

    setAnswerText("");
  };
  useEffect(() => {
    setAnswerText("");
  }, [selectedQuestionsList]);
  const dragList = () => {
    if (qIndex + 1 === catData.questions.length) {
      setStep({ step4: false, step5: true });
    } else {
      setQIndex(qIndex + 1);
    }

    let answerArray = [];
    for (let i = 0; i < itemList.length; i++) {
      const element = {};
      element.option_id = itemList[i].id;
      element.title_en = itemList[i].title_en;

      element.rank = i;

      answerArray.push(element);
    }
    let data = {
      question_id: qData.id,
      question_title: qData.text_en,

      answers: answerArray,
      category_id: catData.id,
    };
    setSelectedQuestionsList([...selectedQuestionsList, data]);
  };

  const pickNew = () => {
    setStep({ step6: false, step7: true });
    if (catData) {
      let data = {
        id: catData.id,
        category: catData,
        questions: selectedQuestionsList,
      };
      setCatArray([...catArray, data]);
    }
    setCatSelectedOption(null);
    setQIndex(0);
  };

  const answerList = () => {
    if (catData) {
      let data = {
        id: catData.id,
        category: catData,
        questions: selectedQuestionsList,
      };
      setCatArray([...catArray, data]);
    }
    setStep({ step5: false, step6: true });
  };

  useEffect(() => {
    const idArray = catArray.map((cat) => cat.id);

    const userList = questionsList.filter((item) => {
      return idArray.indexOf(item.id) === -1;
    });
    setQuestions(userList);
    setSelectedQuestionsList([]);
  }, [catArray]);

  useEffect(() => {
    if (catSelected !== null) {
    
       let cat = catSelected;
       let selectedQuestions = []
       let questions = catSelected.questions;
       for(let i=0; i < questions.length; i++) {
         if(questions[i].countries != null) {
           const sub = JSON.parse(questions[i].countries);
          for(let j=0;j < sub.length; j++ ) {

          if(sub[j] == selectedOption.country) {
            selectedQuestions.push(questions[i])

          }
          }
         }
         if(questions[i].countries.length == 0 ||  questions[i].countries == null) {
          selectedQuestions.push(questions[i])

         }
         
       }
           cat.questions = selectedQuestions;

        setCatData(cat);
        setQData(selectedQuestions[qIndex]);


      // if (selectedOption.country === "Egypt") {
      //   let cat = catSelected;
      //   const catList = catSelected.questions.filter((item) => {
      //     return item.uae_only === 0;
      //   });
      //   cat.questions = catList;

      //   setCatData(cat);
      //   setQData(catList[qIndex]);
      // } else if (selectedOption.country === "UAE") {
      //   let cat = catSelected;
      //   const catList = catSelected.questions.filter((item) => {
      //     return item.egypt_only === 0;
      //   });
      //   cat.questions = catList;

      //   setCatData(cat);
      //   setQData(catList[qIndex]);
      // } else if (
      //   selectedOption.country !== "Egypt" ||
      //   selectedOption.country !== "UAE"
      // ) {
      //   let cat = catSelected;
      //   const catList = catSelected.questions.filter((item) => {
      //     if (item.uae_only === 0 && item.egypt_only === 0) {
      //       return item;
      //     }
      //   });
      //   cat.questions = catList;

      //   setCatData(cat);
      //   setQData(catList[qIndex]);
      // }
    }
  }, [catSelected, qIndex]);

  const getQuestions = () => {
    ServiceBase()
      .get("categories")
      .then((res) => {
        setQuestions(res.data);
        setCompareList(res.data);
      })
      .catch((error) => console.log(error));
  };

  useEffect(getQuestions, []);

  const getMalls = () => {
    ServiceBase()
      .get("malls")
      .then((res) => {
        if (lang == "ar") {
          const options = res.data.map(function (row) {
            return {
              value: row.title_ar,
              label: row.title_ar,
              id: row.id,
              country: row.country,
            };
          });
          setMallsList(options);
        } else {
          const options = res.data.map(function (row) {
            return {
              value: row.title_en,
              label: row.title_en,
              id: row.id,
              country: row.country,
            };
          });
          setMallsList(options);
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(getMalls, [lang]);

  const getAge = () => {
    if (lang == "ar") {
      const options = ageOptions.map(function (row) {
        return {
          value: row.title_ar,
          label: row.title_ar,
        };
      });
      setAgeList(options);
    } else {
      const options = ageOptions.map(function (row) {
        return {
          value: row.title_en,
          label: row.title_en,
          id: row.id,
          country: row.country,
        };
      });
      setAgeList(options);
    }
  };

  useEffect(getAge, [lang]);
  useEffect(() => {}, [ageList]);


  const getLoyalty = () => {
    if (lang == "ar") {
      const options = loyalityOption.map(function (row) {
        return {
          value: row.value,
          label: row.title_ar,
        };
      });
      setLoyalList(options);
    } else {
      const options = loyalityOption.map(function (row) {
        return {
          value: row.value,
          label: row.title_en,
         
        };
      });
      setLoyalList(options);
    }
  };

  useEffect(getLoyalty, [lang]);
  useEffect(() => {}, [loyalList]);

  const getResidence = () => {
    if (lang == "ar") {
      const options = residenceOptions.map(function (row) {
        return {
          value: row.title_ar,
          label: row.title_ar,
        };
      });
      setResidenceList(options);
    } else {
      const options = residenceOptions.map(function (row) {
        return {
          value: row.title_en,
          label: row.title_en,
          id: row.id,
          country: row.country,
        };
      });
      setResidenceList(options);
    }
  };

  useEffect(getResidence, [lang]);
  useEffect(() => {}, [ residenceList]);

  const getFrequentlyVisit = () => {
    if (lang == "ar") {
      const options = frequentlyVisitOptions.map(function (row) {
        return {
          value: row.title_ar,
          label: row.title_ar,
        };
      });
      setFrequentlyVisitList(options);
    } else {
      const options = frequentlyVisitOptions.map(function (row) {
        return {
          value: row.title_en,
          label: row.title_en,
          id: row.id,
          country: row.country,
        };
      });
      setFrequentlyVisitList(options);
    }
  };

  useEffect(getFrequentlyVisit, [lang]);
  useEffect(() => {}, [ frequentlyVisitList]);

  const getTimeSpend = () => {
    if (lang == "ar") {
      const options = timeSpendOptions.map(function (row) {
        return {
          value: row.title_ar,
          label: row.title_ar,
        };
      });
      setTimeSpendList(options);
    } else {
      const options = timeSpendOptions.map(function (row) {
        return {
          value: row.title_en,
          label: row.title_en,
          id: row.id,
          country: row.country,
        };
      });
      setTimeSpendList(options);
    }
  };

  useEffect(getTimeSpend, [lang]);
  useEffect(() => {}, [ timeSpendList]);

  const getMoneySpend = () => {
    if (lang == "ar") {
      const options = moneySpendOptions.map(function (row) {
        return {
          value: row.title_ar,
          label: row.title_ar,
        };
      });
      setMoneySpendList(options);
    } else {
      const options = moneySpendOptions.map(function (row) {
        return {
          value: row.title_en,
          label: row.title_en,
          id: row.id,
          country: row.country,
        };
      });
      setMoneySpendList(options);
    }
  };

  useEffect(getMoneySpend, [lang]);
  useEffect(() => {}, [ moneySpendList]);

  const getMoneySpendLebanon = () => {
    if (lang == "ar") {
      const options = moneySpendOptionsLebanon.map(function (row) {
        return {
          value: row.title_ar,
          label: row.title_ar,
        };
      });
      setMoneySpendListLebanon(options);
    } else {
      const options = moneySpendOptionsLebanon.map(function (row) {
        return {
          value: row.title_en,
          label: row.title_en,
          id: row.id,
          country: row.country,
        };
      });
      setMoneySpendListLebanon(options);
    }
  };

  useEffect(getMoneySpendLebanon, [lang]);
  useEffect(() => {}, [ moneySpendListLebanon]);

  useEffect(() => {}, [mallsList]);

  useEffect(() => {
    if (qData.type == 3) {
      setItemList(qData.options);
    }
  }, [qData]);

  useEffect(() => {
    setLang(localStorage.getItem("i18nextLng"));
    const enGb = localStorage.getItem("i18nextLng");
    if (enGb == "ar") {
      document.body.classList.add("direction");
    }
    if (enGb != "ar") {
      document.body.classList.remove("direction");
    }
    if (enGb === null) {
      setLang("en");
    } else if (enGb === "en-GB" || enGb === "en-US") {
      setLang("en");
    }

    i18n.on("languageChanged init", () => {
      setLang(localStorage.getItem("i18nextLng"));

      const enGb = localStorage.getItem("i18nextLng");
      if (enGb == "ar") {
        document.body.classList.add("direction");
      }
      if (enGb != "ar") {
        document.body.classList.remove("direction");
      }
      if (enGb === null) {
        setLang("en");
      } else if (enGb === "en-GB" || enGb === "en-US") {
        setLang("en");
      }
    });
  }, []);
  useEffect(() => {
    const userIdentification = { user_email: "some@email.com" }; // optional
    const options = {
      debug: false, // enable logs
    };
    ReactSnapPixel.init(
      "0b100a5f-a529-4761-9b8e-ec3387bc1625",
      userIdentification,
      options
    );

    ReactSnapPixel.pageView();
  }, []);
  const reload = () => {
    window.location.reload();
  };
  const shareFb = () => {
    window.open(
      "https://www.facebook.com/sharer/sharer.php?u=https://citycentrebigvote.com/static/media/social_share.png"
    );
  };
  const shareTwitter = () => {
    window.open(
      "https://www.twitter.com/intent/tweet?url=https://citycentrebigvote.com/&text=I’ve just taken part in the Big City Centre Vote and had my say on how to make my local city centre mall, the perfect place."
    );
  };
  return (
    <>
      {step.step1 && (
        <div className="row align-items-center justify-content-center">
         
          <div className="col-md-8">
           
          </div>
          <div className="col-md-4">
            {lang == "en" && (
              <h2 className="text-md-start">
                Tell us how to make your space a better place.
              </h2>
            )}
            {lang == "ar" && (
              <h2 className="text-md-end">
                .شاركونا بأفكاركم ليصبح هذا المكان... مكانكم
              </h2>
            )}
            {lang == "en" && (
              <span
                className="btn-class mt-4"
                onClick={() => setStep({ step1: false, step2: true })}
              >
                VOTE NOW
              </span>
            )}
            {lang == "ar" && (
              <span
                className="btn-class mt-4"
                onClick={() => setStep({ step1: false, step2: true })}
              >
                صوّتوا الآن
              </span>
            )}
          </div>
        </div>
      )}
      {step.step2 && (
        <div className="row align-items-center justify-content-center">
          <div className="col-md-8">
            {lang == "en" && (
              <img src={leftImg} alt="logo" className="img-fluid left-img" />
            )}
            {lang == "ar" && (
              <img
                src={arabicBingC}
                alt="logo"
                className="img-fluid left-img"
              />
            )}
          </div>
          <div className="col-md-4 mb-5">
            {lang == "en" && (
              <h2 className="text-md-start mall-title-heading">
                It's your mall. So we're <br />
                putting you in charge.
              </h2>
            )}
            {lang == "ar" && (
              <h2 className="text-md-end">،شاركوا بصناعة القرار في مركزكم</h2>
            )}

            <Select
              className={`select-box ${lang == "ar" ? "ar-select" : ""}`}
              onChange={handleChange}
              classNamePrefix="react-select"
              placeholder={t("placeholder")}
              options={mallsList}
              isSearchable={false}
            />
            {!selectedOption && (
              <span className="btn-class mt-4">{t("start")}</span>
            )}

            {selectedOption && (
              <span
                className="btn-class mt-4 orange"
                onClick={() => setStep({ step2: false, step3: true })}
              >
                {t("start")}
              </span>
            )}
          </div>
        </div>
      )}
      {step.step3 && (
        <>
          <div className="row">
            <div className="col-md-12 text-center mt-3">
              {lang == "en" && (
                <img
                  src={cityLogo}
                  alt="img_desc"
                  className="img-fluid"
                  width={130}
                />
              )}
              {lang == "ar" && (
                <img
                  src={arabicSmall}
                  alt="img_desc"
                  className="img-fluid"
                  width={130}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              {lang == "en" && (
                <h1>
                  Make this space
                  <br />
                  your perfect place.
                </h1>
              )}
              {lang == "ar" && (
                <h1>
                  ،شاركوا بصناعة القرار
                  <br /> في مركزكم
                </h1>
              )}
            </div>
          </div>
          <div className="row my-3 justify-content-center align-items-center">
            <div className="col-3 text-center">
              <hr className="vote-hr" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              {lang == "en" && (
                <p>
                  We want to build the best malls in the world. But we can’t do
                  that without you. So we’re listening to your ideas. Each
                  category below contains a set of different questions based on
                  different aspects of your local City Centre mall. Some are
                  multiple choice; some might simply ask for a suggestion.
                  &nbsp;
                  <br />
                  <b>
                    It shouldn’t take more than 5 minutes per category, and you
                    could get a chance to win 50,000 SHARE points or a Mall
                    GiftCard.
                  </b>
                </p>
              )}
              {lang == "ar" && (
                <p dir="rtl">
                  الجميع يبحث عن الأفضل، بما في ذلك نحن في سيتي سنتر. لكن وصولنا
                  إلى الأفضل يحتاج مشاركة كل الأطراف، نحن وأنتم... تتضمن كل فئة
                  أدناه مجموعة من الأسئلة حول جوانب مختلفة تتعلق بسيتي سنتر
                  الأقرب إليكم. بعض هذه الأسئلة مع خيارات وبعضها يطلب اقتراحاً
                  منكم. بأقل من 5 دقائق لكل فئة، ستحصلون يومياً على فرصة للفوز
                  بـ 50,000 نقطة "شير" أو بطاقة "هديتي".
                </p>
              )}
            </div>
          </div>
          <div className="row mt-3 ">
            <>
              {questionsList.map((item, index) => (
                <div
                  className="col-md-2 col-xs-4 col-sm-4 col-6 cat-list"
                  key={index}
                >
                  <div
                    className={`${catSelected === item ? "active" : ""}`}
                    onClick={() => setCatSelectedOption(item)}
                  >
                    <div className="img-wrapper">
                      <img
                        src={`https://citycentrebigvote.com/bigvote_backend/${item.icon}`}
                        alt="cat_img"
                        className="img-fluid my-3"
                      />
                    </div>
                    {lang == "en" && <span>{item.title_en}</span>}
                    {lang == "ar" && <span>{item.title_ar}</span>}
                  </div>
                </div>
              ))}
            </>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              {!catSelected && (
                <span className="btn-class mt-4">{t("start")}</span>
              )}
              {catSelected && (
                <span
                  className="btn-class mt-4 orange"
                  onClick={() => setStep({ step3: false, step4: true })}
                >
                  {t("start")}
                </span>
              )}
            </div>
          </div>
        </>
      )}
      {step.step4 && (
        <>
          <div className="row">
            <div className="col-md-12 text-center">
              {lang == "en" && (
                <img
                  src={cityLogo}
                  alt="img_desc"
                  className="img-fluid"
                  width={130}
                />
              )}
              {lang == "ar" && (
                <img
                  src={arabicSmall}
                  alt="img_desc"
                  className="img-fluid"
                  width={130}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              <h1 className="q-text mt-3">
                {lang == "en" && (
                  <div
                    dangerouslySetInnerHTML={{ __html: qData.text_en }}
                  ></div>
                )}
                {lang == "ar" && (
                  <div
                    dangerouslySetInnerHTML={{ __html: qData.text_ar }}
                  ></div>
                )}

               
              </h1>
            </div>
          </div>
          <div className="row mt-2 justify-content-center align-items-center">
            <div className="col-3 text-center">
              <hr className="vote-hr" />
            </div>
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <div className="text-capitalize q-label">
                {t("category")}{" "}
                <span>
                  <b>
                    {lang == "en" && <span>{catData?.title_en}</span>}
                    {lang == "ar" && <span>{catData?.title_ar}</span>}
                  </b>
                </span>
              </div>
            </div>
          </div>
          <div className="row mt-3 align-content-center justify-content-center cat-ar-direction">
            <>
              {qData.type == 0 && (
                <>
                  <>
                    {qData.options.map((item, index) => (
                      <div className="o-col" key={item.id}>
                        <label
                          className={`radio-wrapper mb-3 ${
                            active === item ? "active" : ""
                          }`}
                        >
                          <input
                            className={`radio`}
                            type="radio"
                            onChange={() => changeList(item, index)}
                            value={item.id}
                            name="option"
                          />
                          {lang == "en" && <span>{item.title_en}</span>}
                          {lang == "ar" && <span>{item.title_ar}</span>}
                        </label>
                      </div>
                    ))}
                  </>

                  {active === "other" && (
                    <div className="col-12">
                      <input
                        type="text"
                        onChange={(e) => setAnswerText(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  )}
                  <div className="row mt-3">
                    <div className="col-md-12">
                      {active == "other" && answerText && (
                        <span
                          className="btn-class mt-4 orange"
                          onClick={changeFinalList}
                        >
                          {t("vote")}
                        </span>
                      )}
                      {active && active != "other" && (
                        <span
                          className="btn-class mt-4 orange"
                          onClick={changeFinalList}
                        >
                          {t("vote")}
                        </span>
                      )}
                      {!active && (
                        <span className="btn-class mt-4">{t("vote")}</span>
                      )}
                      {active == "other" && !answerText && (
                        <span className="btn-class mt-4">{t("vote")}</span>
                      )}
                    </div>
                  </div>
                </>
              )}

              {qData.type == 1 && (
                <>
                  {qData.options.map((item, index) => (
                    <div className="o-col" key={index}>
                      <label
                        className={`radio-wrapper mb-3 ${
                          selectedCheckbox.indexOf(item) !== -1 ? "active" : ""
                        }`}
                      >
                        <input
                          className={`radio`}
                          type="checkbox"
                          onChange={() => changeCheckboxes(item, index)}
                          value={item.id}
                          name={item.title_en}
                        />
                        {lang == "en" && <span>{item.title_en}</span>}
                        {lang == "ar" && <span>{item.title_ar}</span>}
                      </label>
                    </div>
                  ))}

                  {activeMultiple === "other" && (
                    <div className="col-12">
                      <input
                        type="text"
                        onChange={(e) => setAnswerText(e.target.value)}
                        className="form-control"
                      />
                    </div>
                  )}
                  <div className="row mt-3">
                    <div className="col-md-12">
                      {activeMultiple == "other" && answerText && (
                        <span
                          className="btn-class mt-4 orange"
                          onClick={changeListCheckBox}
                        >
                          {t("vote")}
                        </span>
                      )}
                      {activeMultiple != "other" &&
                        selectedCheckbox.length > 0 && (
                          <span
                            className="btn-class mt-4 orange"
                            onClick={changeListCheckBox}
                          >
                            {t("vote")}
                          </span>
                        )}
                      {!activeMultiple && selectedCheckbox.length == 0 && (
                        <span className="btn-class mt-4">{t("vote")}</span>
                      )}
                      {activeMultiple == "other" && !answerText && (
                        <span className="btn-class mt-4">{t("vote")}</span>
                      )}
                    </div>
                  </div>

               
                </>
              )}

              {qData.type == 2 && (
                <>
                  <div className="row align-content-center justify-content-center">
                    <div className="col-10 text-center">
                      <textarea
                        name=""
                        id=""
                        cols="30"
                        rows="2"
                        onChange={(e) => setAnswerText(e.target.value)}
                        placeholder={t("write_your")}
                        value={answerText}
                      ></textarea>
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-12">
                      <span className="btn-class mt-4" onClick={textAnswerList}>
                        {t("vote")}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <span className="btn-class" onClick={textAnswerList}>
                        {t("skip")}
                      </span>
                    </div>
                  </div>
                </>
              )}

              {qData.type == 3 && (
                <>
                  <div className="App">
                    <DragDropContext onDragEnd={handleDrop}>
                      <Droppable droppableId="list-container">
                        {(provided) => (
                          <div
                            className="list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {itemList.map((item, index) => (
                              <Draggable
                                key={item.id}
                                draggableId={item.id.toString()}
                                index={index}
                              >
                                {(provided) => (
                                  <div
                                    className="item-container mb-3"
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    {lang == "en" && (
                                      <span>{item.title_en}</span>
                                    )}
                                    {lang == "ar" && (
                                      <span>{item.title_ar}</span>
                                    )}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-12">
                      <span className="btn-class mt-4" onClick={dragList}>
                        {t("vote")}
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
 
          <div className="row mt-5">
            <div className="col-12 text-center">
              <div className="q-index">
                {t("question")}{" "}
                <span>
                  <b>
                    {" "}
                    {qIndex + 1} {t("of")} {catData?.questions.length}
                  </b>
                </span>
              </div>
            </div>
          </div>
        </>
      )}

      {step.step5 && (
        <div className="row align-items-center justify-content-center">
          <div className="col-md-8">
            {lang == "en" && (
              <img src={finalLogo} alt="logo" className="img-fluid left-img" />
            )}
            {lang == "ar" && (
              <img
                src={arabicBingC}
                alt="logo"
                className="img-fluid left-img"
              />
            )}
          </div>
          <div className="col-md-4">
            <h2 className="text-center">{t("congrats")}</h2>
            {catArray.length < compareList.length - 1 && (
              <span className="btn-class mt-4 p-4 lg-btn" onClick={pickNew}>
                {t("pick_new")}
              </span>
            )}
            <span className="btn-class mt-2" onClick={answerList}>
              {t("finish")}
            </span>
          </div>
        </div>
      )}

      {step.step6 && (
      <>
        <div className="row">
          <div className="col-md-12 text-center">
            {lang == "en" && (
              <img
                src={cityLogo}
                alt="img_desc"
                className="img-fluid"
                width={130}
              />
            )}
            {lang == "ar" && (
              <img
                src={arabicSmall}
                alt="img_desc"
                className="img-fluid"
                width={130}
              />
            )}
          </div>
        </div>
        {selectedOption.country !== "Egypt" && selectedOption.country !== "Lebanon" &&(
        <span>
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>{t("enter_details")}</h1>
          </div>
        </div>
        <div className="row my-3 justify-content-center align-items-center">
        <div className="col-3 text-center">
          <hr className="vote-hr" />
        </div>
      </div>
      </span>
      )}
      {/* {selectedOption.country == "Oman" &&(
        <span>
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>{t("enter_details")}</h1>
          </div>
        </div>
        <div className="row my-3 justify-content-center align-items-center">
        <div className="col-3 text-center">
          <hr className="vote-hr" />
        </div>
      </div>
      </span>
      )}
      {selectedOption.country == "Bahrain" &&(
        <span>
        <div className="row">
          <div className="col-md-12 text-center">
            <h1>{t("enter_details")}</h1>
          </div>
        </div>
        <div className="row my-3 justify-content-center align-items-center">
        <div className="col-3 text-center">
          <hr className="vote-hr" />
        </div>
      </div>
      </span>
      )} */}
      {selectedOption.country == "Egypt" &&(
        <div className="row">
          <div className="col-md-12 text-center p-4">
            {/* <h1>{t("enter_details")}</h1> */}
          </div>
        </div>
      )}
      {selectedOption.country == "Lebanon" &&(
        <div className="row">
          <div className="col-md-12 text-center p-4">
            {/* <h1>{t("enter_details")}</h1> */}
          </div>
        </div>
      )}
        <form  onSubmit={(e) => e.preventDefault()} className="cat-ar-direction">
          <div className="row">
            <div className="col-md-6">
              <input
                className={`form-control mb-4 ${
                  errors.first_name ? "is-invalid" : ""
                }`}
                autoComplete={'' + Math.random()}
                placeholder={t("f_name")}
                {...register("first_name", { required: true })}
              />
            </div>

            <div className="col-md-6">
              <input
                className={`form-control mb-4 ${
                  errors.last_name ? "is-invalid" : ""
                }`}
                autoComplete={'' + Math.random()}
                placeholder={t("l_name")}
                {...register("last_name", { required: true })}
              />
            </div>
            <div className="col-md-6">
              <input
                className={`form-control mb-4 ${
                  errors.phone ? "is-invalid" : ""
                }`}
                placeholder={t("phone")}
                type="number"
                autoComplete={'' + Math.random()}
                {...register("phone", {
                  required: true,
                  pattern: /\d+/,
                })}
              />
            </div>
            <div className="col-md-6">
              <input
                className={`form-control mb-4 ${
                  errors.email ? "is-invalid" : ""
                }`}
                type="email"
                autoComplete={'' + Math.random()}
                placeholder={t("email")}
                {...register("email", { required: true })}
              />
            </div>
            <div className={`col-md-6 ${errors.age ? "is-invalid" : ""}`}>
            

              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                  const currentSelection = ageList.find(
                    (c) => c.value === value
                  );

                  const handleSelectChange = (selectedOption) => {
                    onChange(selectedOption?.value);
                  };

                  return (
                    <Select
                      className={`select-box form-select-box mb-4 mt-0 ${
                        (errors.age ? "is-invalid" : "",
                        lang == "ar" ? "ar-select" : "")
                      }`}
                      classNamePrefix="react-select"
                      inputRef={ref}
                      placeholder={t("age")}
                      value={currentSelection}
                      name={name}
                      options={ageList}
                      onChange={handleSelectChange}
                    />
                  );
                }}
                name="age"
                rules={{
                  required: true,
                }}
              />
            </div>

            <div className="col-md-6">
              <div className="form-control mb-4">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="mb-0 custom-input-label">{t("marital")}</p>
                  <label
                    htmlFor="single"
                    className="c-label custom-input-label d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      value="0"
                      className={`me-1 ms-1 ${
                        errors.marital_status ? "is-invalid" : ""
                      }`}
                      {...register("marital_status", { required: true })}
                    />
                    {t("single")}
                  </label>
                  <label
                    htmlFor="married"
                    className="c-label custom-input-label d-flex align-items-center"
                  >
                    <input
                      type="radio"
                      className={`me-1 ms-1 ${
                        errors.marital_status ? "is-invalid" : ""
                      }`}
                      value="1"
                      {...register("marital_status", { required: true })}
                    />
                    {t("married")}
                  </label>
                </div>
              </div>
            </div>
          </div>




          {selectedOption.country == "Egypt" &&(
          <div className="row mb-4">
            <div className="col-md-12">
              <input
                className={`form-control mb-4 ${
                  errors.residence ? "is-invalid" : ""
                }`}
                placeholder={t("What is your area of residence?")}
                {...register("residence", { required: true })}
              />
            </div>
            {/* <div className={`col-md-12 ${errors.residence ? "is-invalid" : ""}`}>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                          const currentSelection = residenceList.find(
                          (c) => c.value === value
                          );
          
                          const handleSelectChange = (selectedOption) => {
                          setResidenceValue(selectedOption?.value);
                          onChange(selectedOption?.value)
                          };
          
                          return (
                              <Select
                                className={`select-box form-select-box mb-4 mt-0 ${
                                  (errors.residence ? "is-invalid" : "",
                                  lang == "ar" ? "ar-select" : "")
                                }`}
                                classNamePrefix="react-select"
                                inputRef={ref}
                                placeholder={t("What is your area of residence?")}
                                value={currentSelection}
                                name={name}
                                options={residenceList}
                                onChange={handleSelectChange}
                              />
                            );
                          }}
                          name="residence"
                          rules={{
                            required: false,
                          }}
                        />
            </div>
          
            { residenceValue == "Other (please specify)" && (
              <div className="col-12 mb-4">
                <input
                  type="text"
                  onChange={(e) => setResidenceOther(e.target.value)}
                  className="form-control"
                />
              </div>
            )} */}

            <div className={`col-md-12 ${errors.frequently_visit ? "is-invalid" : ""}`}>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                  const currentSelection = frequentlyVisitList.find(
                    (c) => c.value === value
                  );

                  const handleSelectChange = (selectedOption) => {
                    onChange(selectedOption?.value);
                  };

                  return (
                    <Select
                      className={`select-box form-select-box mb-4 mt-0 ${
                        (errors.frequently_visit ? "is-invalid" : "",
                        lang == "ar" ? "ar-select" : "")
                      }`}
                      classNamePrefix="react-select"
                      inputRef={ref}
                      placeholder={t("How frequently do you visit our City Centre malls in Egypt?*")}
                      value={currentSelection}
                      name={name}
                      options={frequentlyVisitList}
                      onChange={handleSelectChange}
                    />
                  );
                }}
                name="frequently_visit"
                rules={{
                  required: true,
                }}
              />
            </div>

            <div className={`col-md-12 ${errors.time_spend ? "is-invalid" : ""}`}>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                  const currentSelection = timeSpendList.find(
                    (c) => c.value === value
                  );

                  const handleSelectChange = (selectedOption) => {
                    onChange(selectedOption?.value);
                  };

                  return (
                    <Select
                      className={`select-box form-select-box mb-4 mt-0 ${
                        (errors.time_spend ? "is-invalid" : "",
                        lang == "ar" ? "ar-select" : "")
                      }`}
                      classNamePrefix="react-select"
                      inputRef={ref}
                      placeholder={t("On average, how much time do you spend at our City Centre malls per visit?*")}
                      value={currentSelection}
                      name={name}
                      options={timeSpendList}
                      onChange={handleSelectChange}
                    />
                  );
                }}
                name="time_spend"
                rules={{
                  required: true,
                }}
              />
            </div>

            <div className={`col-md-12 ${errors.money_spend ? "is-invalid" : ""}`}>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                  const currentSelection = moneySpendList.find(
                    (c) => c.value === value
                  );

                  const handleSelectChange = (selectedOption) => {
                    onChange(selectedOption?.value);
                  };

                  return (
                    <Select
                      className={`select-box form-select-box mt-0 ${
                        (errors.money_spend ? "is-invalid" : "",
                        lang == "ar" ? "ar-select" : "")
                      }`}
                      classNamePrefix="react-select"
                      inputRef={ref}
                      placeholder={t("On average, how much do you spend at our City Centre malls per month?*")}
                      value={currentSelection}
                      name={name}
                      options={moneySpendList}
                      onChange={handleSelectChange}
                    />
                  );
                }}
                name="money_spend"
                rules={{
                  required: true,
                }}
              />
            </div>

          </div>
          )}


          {selectedOption.country == "Lebanon" &&(
          <div className="row mb-4">
            {/* <div className="col-md-12">
              <input
                className={`form-control mb-4 ${
                  errors.residence ? "is-invalid" : ""
                }`}
                placeholder={t("What is your area of residence?")}
                {...register("residence", { required: true })}
              />
            </div> */}
            <div className={`col-md-12 ${errors.residence ? "is-invalid" : ""}`}>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                          const currentSelection = residenceList.find(
                          (c) => c.value === value
                          );
          
                          const handleSelectChange = (selectedOption) => {
                          setResidenceValue(selectedOption?.value);
                          onChange(selectedOption?.value)
                          };
          
                          return (
                              <Select
                                className={`select-box form-select-box mb-4 mt-0 ${
                                  (errors.residence ? "is-invalid" : "",
                                  lang == "ar" ? "ar-select" : "")
                                }`}
                                classNamePrefix="react-select"
                                inputRef={ref}
                                placeholder={t("What is your area of residence?")}
                                value={currentSelection}
                                name={name}
                                options={residenceList}
                                onChange={handleSelectChange}
                              />
                            );
                          }}
                          name="residence"
                          rules={{
                            required: false,
                          }}
                        />
            </div>
          
            { residenceValue == "Other (please specify)" && (
              <div className="col-12 mb-4">
                <input
                  type="text"
                  onChange={(e) => setResidenceOther(e.target.value)}
                  className="form-control"
                />
              </div>
            )}

            <div className={`col-md-12 ${errors.frequently_visit ? "is-invalid" : ""}`}>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                  const currentSelection = frequentlyVisitList.find(
                    (c) => c.value === value
                  );

                  const handleSelectChange = (selectedOption) => {
                    onChange(selectedOption?.value);
                  };

                  return (
                    <Select
                      className={`select-box form-select-box mb-4 mt-0 ${
                        (errors.frequently_visit ? "is-invalid" : "",
                        lang == "ar" ? "ar-select" : "")
                      }`}
                      classNamePrefix="react-select"
                      inputRef={ref}
                      placeholder={t("How frequently do you visit City Centre Beirut?*")}
                      value={currentSelection}
                      name={name}
                      options={frequentlyVisitList}
                      onChange={handleSelectChange}
                    />
                  );
                }}
                name="frequently_visit"
                rules={{
                  required: true,
                }}
              />
            </div>

            <div className={`col-md-12 ${errors.time_spend ? "is-invalid" : ""}`}>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                  const currentSelection = timeSpendList.find(
                    (c) => c.value === value
                  );

                  const handleSelectChange = (selectedOption) => {
                    onChange(selectedOption?.value);
                  };

                  return (
                    <Select
                      className={`select-box form-select-box mb-4 mt-0 ${
                        (errors.time_spend ? "is-invalid" : "",
                        lang == "ar" ? "ar-select" : "")
                      }`}
                      classNamePrefix="react-select"
                      inputRef={ref}
                      placeholder={t("On average, how much time do you spend at City Centre Beirut per visit?*")}
                      value={currentSelection}
                      name={name}
                      options={timeSpendList}
                      onChange={handleSelectChange}
                    />
                  );
                }}
                name="time_spend"
                rules={{
                  required: true,
                }}
              />
            </div>

            <div className={`col-md-12 ${errors.money_spend ? "is-invalid" : ""}`}>
              <Controller
                control={control}
                render={({ field: { onChange, value, name, ref } }) => {
                  const currentSelection = moneySpendListLebanon.find(
                    (c) => c.value === value
                  );

                  const handleSelectChange = (selectedOption) => {
                    onChange(selectedOption?.value);
                  };

                  return (
                    <Select
                      className={`select-box form-select-box mt-0 ${
                        (errors.money_spend ? "is-invalid" : "",
                        lang == "ar" ? "ar-select" : "")
                      }`}
                      classNamePrefix="react-select"
                      inputRef={ref}
                      placeholder={t("On average, how much do you spend at City Centre Beirut per month?*")}
                      value={currentSelection}
                      name={name}
                      options={moneySpendListLebanon}
                      onChange={handleSelectChange}
                    />
                  );
                }}
                name="money_spend"
                rules={{
                  required: true,
                }}
              />
            </div>

          </div>
          )}




          {selectedOption.country == "UAE" &&(

          <div className="row mb-4">
            <div className="col-12">
              <input
                className={`form-control`}
                type="text"
                placeholder={t("share_number")}
                {...register("share_number")}
              />
            </div>
          </div>
          )}
          {selectedOption.country != "UAE" &&(
          <div className="row mb-4">
          <div className={`col-md-12`}>
            

            <Controller
              control={control}
              render={({ field: { onChange, value, name, ref } }) => {
                const currentSelection = loyalList.find(
                  (c) => c.value === value
                );

                const handleSelectChange = (selectedOption) => {
                  onChange(selectedOption?.value);
                };

                return (
                  <Select
                    className={`select-box form-select-box mb-4 mt-0 ${
                      (errors.want_loyalty ? "is-invalid" : "",
                      lang == "ar" ? "ar-select" : "")
                    }`}
                    classNamePrefix="react-select"
                    inputRef={ref}
                    placeholder={t("loyalty")}
                    value={currentSelection}
                    name={name}
                    options={loyalList}
                    onChange={handleSelectChange}
                  />
                );
              }}
              name="want_loyalty"
            
            />
          </div>
          </div>
          )}
          <div className="row ms-1">
            <div className="col-md-12 text-start ag-ar">
              {lang == "en" && (
                <label htmlFor="check">
                  <input
                    type="checkbox"
                    {...register("check", { required: true })}
                  
                    className={`form-check-input me-1 ${
                        errors.check ? "is-invalid" : ""
                      }`}
                  />
                  Yes, I consent to Majid Al Futtaim’s{" "}
                  <NavLink
                    className="custom-anchor"
                    target="_blank"
                    to={"/" + lang + "/pp"}
                  >
                    privacy policy
                  </NavLink>
                  .
                </label>
              )}
              {lang == "ar" && (
                <label htmlFor="check">
                  <input
                    type="checkbox"
                    {...register("check", { required: true })}
                    className="form-check-input ms-1"
                  />
                  نعم، أوافق على{" "}
                  <NavLink
                    className="custom-anchor"
                    target="_blank"
                    to={"/" + lang + "/pp"}
                  >
                    سياسة الخصوصية
                  </NavLink>{" "}
                  الخاصة بشركة ماجد الفطيم.
                </label>
              )}
            </div>
          </div>

          {(errors.first_name ||
            errors.last_name ||
            errors.share_number ||
            errors.phone ||
            errors.email ||
            errors.check ||
            errors.age ||
            errors.marital_status ||
            errors.residence ||
            errors.frequently_visit ||
            errors.time_spend ||
            errors.money_spend) && (
            <>
              {lang == "en" && (
                <div className="row mt-3">
                  <div className="col-md-12 text-start ms-3">
                    <p style={{ color: "#dc3545" }}>
                      Fields marked with * are mandatory
                    </p>
                  </div>
                </div>
              )}
              {lang == "ar" && (
                <div className="row mt-3">
                  <div className="col-md-12 text-end">
                    <p style={{ color: "#dc3545" }}>
                      الحقل المشار إليه بعلامة * إلزامي
                    </p>
                  </div>
                </div>
              )}
            </>
          )}





          {!isValid && (
            <div className="row mt-5">
              <div className="col-12">
                <span
                  className="btn-class mt-2"
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("submit")}
                </span>
              </div>
            </div>
          )}
          {isValid && (
            <div className="row mt-5">
              <div className="col-12">
                <span
                  className="btn-class mt-2 orange"
                  disabled={!isValid}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t("submit")}
                </span>
              </div>
            </div>
          )}
          <ToastContainer />
        </form>
      </>
      )}

      {step.step7 && (
        <>
          <div className="row">
            <div className="col-md-12 text-center">
              {lang == "en" && (
                <img
                  src={cityLogo}
                  alt="img_desc"
                  className="img-fluid"
                  width={130}
                />
              )}
              {lang == "ar" && (
                <img
                  src={arabicSmall}
                  alt="img_desc"
                  className="img-fluid"
                  width={130}
                />
              )}
            </div>
          </div>

          <div className="row my-3 justify-content-center align-items-center">
            <div className="col-3 text-center">
              <hr className="vote-hr" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              {lang == "en" && (
                <p>
                  We want to build the best malls in the world. But to do that,
                  we need you. Choose a new category, and continue voting to
                  help us make this space, your perfect place.
                </p>
              )}
              {lang == "ar" && (
                <p>
                  الجميع يبحث عن الأفضل، بما في ذلك نحن في سيتي سنتر. لكن وصولنا
                  إلى الأفضل يحتاج آرائكم واقتراحاتكم، اختاروا فئة جديدة
                  واستمروا في التصويت لمساعدتنا في أن يصبح هذا المكان، مكانكم
                  الأفضل.
                </p>
              )}
            </div>
          </div>
          <div className="row mt-3 ">
            <>
              {catArray.map((item, index) => (
                <div
                  className="col-md-2 col-xs-4 col-sm-4 col-6 cat-list not-allowed"
                  key={index}
                >
                  <div className="active">
                    <div className="img-wrapper">
                      <img
                        src={`https://citycentrebigvote.com/bigvote_backend/${item.category.icon}`}
                        alt="cat_img"
                        className="img-fluid my-3"
                      />
                    </div>
                    {lang == "en" && <span>{item.category.title_en}</span>}
                    {lang == "ar" && <span>{item.category.title_ar}</span>}
                  </div>
                </div>
              ))}
              {questionsList.map((item, index) => (
                <div
                  className="col-md-2 col-xs-4 col-sm-4 col-6 cat-list"
                  key={index}
                >
                  <div
                    className={`${catSelected === item ? "active" : ""}`}
                    onClick={() => setCatSelectedOption(item)}
                  >
                    <div className="img-wrapper">
                      <img
                        src={`https://citycentrebigvote.com/bigvote_backend/${item.icon}`}
                        alt="cat_img"
                        className="img-fluid my-3"
                      />
                    </div>
                    {lang == "en" && <span>{item.title_en}</span>}
                    {lang == "ar" && <span>{item.title_ar}</span>}
                  </div>
                </div>
              ))}
            </>
          </div>
          <div className="row mt-3">
            <div className="col-md-12">
              {!catSelected && (
                <span className="btn-class mt-4">{t("start")}</span>
              )}
              {catSelected && (
                <span
                  className="btn-class mt-4 orange"
                  onClick={() => setStep({ step3: false, step4: true })}
                >
                  {t("start")}
                </span>
              )}
            </div>
          </div>
        </>
      )}

      {step.step8 && (
        <>
          <div className="row">
            <div className="col-md-12 text-center">
              {lang == "en" && (
                <img
                  src={cityLogo}
                  alt="img_desc"
                  className="img-fluid"
                  width={130}
                />
              )}
              {lang == "ar" && (
                <img
                  src={arabicSmall}
                  alt="img_desc"
                  className="img-fluid"
                  width={130}
                />
              )}
            </div>
          </div>
          <div className="row my-3 justify-content-center align-items-center">
            <div className="col-3 text-center">
              <hr className="vote-hr" />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 text-center">
              {lang == "en" && (
                <h1>
                  Thank you for taking part in
                  <br />
                  The Big City Centre Vote
                </h1>
              )}
              {lang == "ar" && (
                <h1>.شكراً لكم على المشاركة في تصويت سيتي سنتر الكبير</h1>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12 text-center">
              {lang == "en" && (
                <p>
                  Share with your friends so they can also help us make this
                  space, your perfect place.
                </p>
              )}
              {lang == "ar" && (
                <p>
                  {" "}
                  .لزيادة فرصكم في الفوز، شاركوا هذا الاستبيان مع أصدقائكم
                  وعائلاتكم
                </p>
              )}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-12 text-center">
              <div className="btn-class lg-btn ">
              

                <a className="me-3" onClick={shareFb}>
                  <img src={fb} alt="fb" height={20} />
                </a>
                <a className="me-3" onClick={shareTwitter}>
                  <img src={twitter} alt="fb" height={20} />
                </a>

                <CopyToClipboard text="https://citycentrebigvote.com/">
                  <span data-tip="Copy Link">
                    <img src={share} alt="share" height={20} />
                  </span>
                </CopyToClipboard>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-center">
              <span className="btn-class" onClick={reload}>
                {t("start_over")}
              </span>
            </div>
          </div>
          <ReactTooltip />
        </>
      )}
    </>
  );
};
export default withNamespaces()(Vote);
