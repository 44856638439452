import { useState, useEffect } from "react";
import i18n from "../../i18n";
import board from "../../assets/images/boardroom.webp";
import ReactSnapPixel from "react-snapchat-pixel";

const BroadRoom = () => {
  const [lang, setLang] = useState("");
  useEffect(() => {
    setLang(localStorage.getItem("i18nextLng"));
    const enGb = localStorage.getItem("i18nextLng");
    if (enGb === null) {
      setLang("en");
    } else if (enGb === "en-GB" || enGb === "en-US") {
      setLang("en");
    }

    i18n.on("languageChanged init", () => {
      setLang(localStorage.getItem("i18nextLng"));
      const enGb = localStorage.getItem("i18nextLng");
      if (enGb === null) {
        setLang("en");
      } else if (enGb === "en-GB" || enGb === "en-US") {
        setLang("en");
      }
    });
  }, []);
  useEffect(() => {
    const userIdentification = { user_email: "some@email.com" }; // optional
    const options = {
      debug: false, // enable logs
    };
    ReactSnapPixel.init(
      "0b100a5f-a529-4761-9b8e-ec3387bc1625",
      userIdentification,
      options
    );

    ReactSnapPixel.pageView();
  }, []);
  document.body.classList.remove("home");

  return (
    <>
      {lang == "en" && (
        <div className="row mt-5">
          <div className="col-md-12">
            <img src={board} alt="img" className="img-fluid" />
          </div>
          <div className="col-md-12 mt-3">
            <h3>
              How do you build the best malls in the world? <br /> Well, we’re
              still trying to figure that out. But, if there’s one thing we know
              for sure, it’s that it can’t be done from inside the walls of our
              boardroom or our headquarters.
            </h3>
          </div>
          <div className="col-md-12 mt-3">
            <p>
              So, we decided to move our boardroom table into the middle of our
              City Centre malls, to create a little more ‘room’ in the
              boardroom, for the thousands of other opinions that matter. Yours,
              our customers.
            </p>
          </div>
          <div className="col-md-12">
            <p>
              <b>Take your seat at the top table.</b>
            </p>
          </div>
        </div>
      )}
      {lang == "ar" && (
        <div className="row mt-5" dir="rtl">
          <div className="col-md-12">
            <img src={board} alt="img" className="img-fluid" />
          </div>
          <div className="col-md-12 mt-3">
            <h3>
              كيف نبتكر أفضل مراكز التسوّق في العالم؟ الجميع يبحث عن الأفضل، بما
              في ذلك نحن في سيتي سنتر... نجتمع، نفكر ونقرر. لكننا اكتشفنا أن
              الوصول إلى الأفضل يحتاج مشاركة كل الأطراف، نحن وأنتم...
            </h3>
          </div>
          <div className="col-md-12 mt-3">
            <p>
              لذا، قررنا نقل طاولة مجلس الإدارة إلى وسط مراكز سيتي سنتر، لنوسع
              اجتماعاتنا ونتعرّف على آلاف الآراء القيّمة، آراء عملائنا الأعزاء.
            </p>
          </div>
          <div className="col-md-12">
            <p>
              <b>خذوا مقعدكم على الطاولة وشاركونا بمقترحاتكم.</b>
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default BroadRoom;
