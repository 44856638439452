import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import backend from "i18next-xhr-backend";
import { reactI18nextModule } from "react-i18next";

// translations are already at
// '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from
const language = localStorage.getItem("i18nextLng");
if (!language) {
  localStorage.setItem("i18nextLng", "en");
}

i18n
  .use(detector)
  .use(backend)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    fallbackLng:'en', // use en if detected lng is not available
    lng: localStorage.getItem("i18nextLng") || "en",        // default value


  //  fallbackLng: (code) => {
  //   if (!code || code === 'en') return ['en-US'];
  //   const fallbacks = [code];

  //   // We maintain en-US and en-AU. Some regions will prefer en-AU.
  //   if (code.startsWith('en-') && !['en-US', 'en-AU'].includes(code)) {
  //     if (['en-GB', 'en-NZ', 'en-IR'].includes(code)) fallbacks.push('en-AU');
  //     else fallbacks.push('en-US');
  //     return fallbacks;
  //   }
  
  // },

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;