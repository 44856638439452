import { useEffect, useState } from "react";
import { NavLink,useNavigate,useLocation } from "react-router-dom";
import i18n from '../i18n'

const Footer = () => {
  const [lang,setLang] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();


  useEffect(() => {
     setLang( localStorage.getItem('i18nextLng'))
    const enGb = localStorage.getItem('i18nextLng');
    if(enGb === null) {
      setLang('en');
    }
    else if(enGb === 'en-GB' || enGb === 'en-US') {
      setLang('en')
    }
    i18n.on("languageChanged init", () => {
      setLang(localStorage.getItem("i18nextLng"));
      const enGb = localStorage.getItem("i18nextLng");
      if (enGb === null) {
        setLang("en");
      } else if (enGb === "en-GB" || enGb === "en-US") {
        setLang("en");
      }
    });
  },[])
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const language = window.location.pathname.split('/')[2]
     const redirectPath = `/${lng}/${language}`;
    //  navigate(redirectPath)
    window.history.replaceState(null, "", redirectPath)
    setLang( localStorage.getItem('i18nextLng'))

  }
const externalUrl = () => {
  window.open("https://www.sharerewards.com/","_blank");

}
  return (
    <>
      <footer className="footer py-3">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg navbar-light">
            <div className="mx-auto d-sm-flex d-block flex-sm-nowrap">
              <div className="collapse navbar-collapse" id="navbarColor03">
                <ul className="navbar-nav mr-auto">






                  {lang == "en" && (
                <li className="nav-item  px-lg-2 px-sm-2 px-1 d-inline-flex">
                  <span className="nav-link ">
                    
                     <span> LANGUAGE: </span>
                   
                  </span>
                  <span
                    className={`nav-link  px-0 pb-0 ${
                      lang === "en" ? "active" : "inactive"
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                     <span>ENGLISH</span>
                  </span>
                  <span
                    className={`nav-link ps-1 pe-0 ${
                      lang === "ar" ? "active" : "inactive"
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    <span>ARABIC</span>
                  </span>
                </li>
              )}

                {lang == "ar" && (
                <li className="nav-item  px-lg-2 px-sm-2 px-1 d-inline-flex" dir="rtl">
                  <span className="nav-link ">
                    {" "}
                    <span> لغة: </span>
                  </span>
                  <span
                    className={`nav-link  px-0 pb-0 ms-1 ${
                      lang === "en" ? "active" : "inactive"
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                     <span>إنكليزي</span> 
                  </span>
                  <span
                    className={`nav-link ps-1 pe-0 ${
                      lang === "ar" ? "active" : "inactive"
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    <span>عربي</span>
                  </span>
                </li>
                )}

                  <li className="nav-item px-lg-2 px-sm-2 px-1">
                    <NavLink end to={lang + "/t&c"} className="nav-link">
                    {lang == "en" && (
                      <span>
                      TERMS & CONDITIONS
                      </span>
                    )}
                    {lang == "ar" && (<span>
                      الشروط والأحكام
                    </span>)}
                    </NavLink>
                  </li>
                  <li className="nav-item px-lg-2 px-sm-2 px-1">
                    <NavLink end to={lang + "/pp"} className="nav-link">
                    {lang == "en" && (<span>
                      PRIVACY POLICY
                      </span>
                    )}
                    {lang == "ar" && (
                      <span>
                      سياسة الخصوصية
</span>                    )}
                    </NavLink>
                  </li>
                  <li className="nav-item px-lg-2 px-sm-2 px-1">
                    <a  onClick={externalUrl} className="nav-link">
                    {lang == "en" && ( <span>
                      SIGN UP FOR SHARE
                      </span>
                    )}
                    {lang == "ar" && (<span>
                      "سجلوا في "شير 
</span>                    )}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </footer>
    </>
  );
};
export default Footer;
