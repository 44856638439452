import { useEffect, useState } from "react";
import i18n from "../i18n";
import { useNavigate ,useSearchParams} from "react-router-dom";
import { withNamespaces } from "react-i18next";
import ReactSnapPixel from 'react-snapchat-pixel';

const Home = ({t}) => {
  const [lang, setLang] = useState("");
  const navigate = useNavigate();

//   const [searchParams, setSearchParams] = useSearchParams();

// const mall = searchParams.get("mallId");
// const name = searchParams.get("name");

// console.log(mall,name)

  document.body.classList.add("home");
  useEffect(() => {
    setLang(localStorage.getItem("i18nextLng"));
    // i18n.on("languageChanged init", (lng) => {
    //   setLang(lng);
    
    // });
  }, [t('current_locale')]);
  useEffect(() => {
    const userIdentification = { user_email: 'some@email.com' }; // optional
    const options = {
        debug: false, 		// enable logs
    };
    ReactSnapPixel.init('0b100a5f-a529-4761-9b8e-ec3387bc1625', userIdentification, options);
     
    ReactSnapPixel.pageView(); 	
  
  }, [])
  const handlenavigate = () => {
    const path = `/${lang}/vote`;
    navigate(path);
  };
// const handleparams = () => {
//   let params = {
//     mallId:1,
//     name:"city"
//   }
//   setSearchParams(params)
// }
  return (
    <>
      <div className="row align-items-center justify-content-center">
        {/* <h2>{t('vote')}</h2> */}
        <div className="col-md-8">
          {/* <img src={leftImg} alt="logo" className="img-fluid" /> */}
        </div>
        <div className="col-md-4">
          {lang == "en" && (
            <h2 className="text-md-start">
              Tell us how to make your space a better place.
            </h2>
          )}
          {lang == "ar" && (
            <h2 className="text-md-end">
              شاركونا بأفكاركم ليصبح هذا المكان... مكانكم الأفضل
            </h2>
          )}
          {lang == "en" && (
            <span className="btn-class mt-lg-4" onClick={handlenavigate}>
              VOTE NOW
            </span>
          )}
          {lang == "ar" && (
            <span className="btn-class mt-lg-4" onClick={handlenavigate}>
              صوّتوا الآن
            </span>
          )}
        </div>
        {/* <div className="col-md-12 text-center">
          <button className="btn btn-dark" onClick={handleparams}> Check params</button>
        </div> */}
      </div>
    </>
  );
};
export default withNamespaces()(Home);
