
const Campaign = () => {
  document.body.classList.remove('home');

  return (
    <>
      <h2>Campaign</h2>
    </>
  );
};
export default Campaign;
