const NotFound = () => {
  document.body.classList.remove('home');

    return (
      <div className='centered'>
        <p>Page not found!</p>
      </div>
    );
  };
  
  export default NotFound;
  