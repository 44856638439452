import Footer from "./Footer";
import Navigation from "./Navbar";
import  "./Layout.css"
const Layout = (props) => {
    return (
      <>
        <Navigation />
        <main className="main">{props.children}</main>
        <Footer/>
        </>
    );
  };
  
  export default Layout;