import axios from 'axios';


const ServiceBase = () => {

    const Api = axios.create({
        baseURL: 'https://citycentrebigvote.com/bigvote_backend/front/',
        // baseURL:  'https://citycentrebigvote.com/bigvote_test_backend/front/',
        headers: {
          'X-Authorization':'5LM3kV5lCrS36fqw32BLnLb1VUi2pUFhfY1jvZxmHv02ON1shWQ4e0FZ9pbtZW3D',
        'Content-Type':'application/json'
    }
    });

    Api.interceptors.request.use((config) => {
        document.body.classList.add('loading-indicator');
        // trigger 'loading=true' event here
        return config;
      }, (error) => {
        document.body.classList.remove('loading-indicator');
        // trigger 'loading=false' event here
        return Promise.reject(error);
      });
    
      Api.interceptors.response.use((response) => {
        document.body.classList.remove('loading-indicator');
        // trigger 'loading=false' event here
        return response;
      }, (error) => {
        document.body.classList.remove('loading-indicator');
        // trigger 'loading=false' event here
        return Promise.reject(error);
      });

    return Api;
};

export default ServiceBase;




