const SignUp = () => {
  document.body.classList.remove('home');

    return (
      <>
        <h2>SignUp</h2>
      </>
    );
  };
  export default SignUp;
  