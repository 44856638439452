import { useState, useEffect } from "react";
import { NavLink,useNavigate } from "react-router-dom";
import "./Navbar.css";
import { withNamespaces } from "react-i18next";
import logo from "../assets/images/cc_logo.webp";
import arabicLogo from "../assets/images/arabic_logo.png";
import arabicLogoBlue from "../assets/images/arabic_blue.png";


import hmburger from "../assets/images/hamburger.png";
import closeMenu from "../assets/images/close.webp";

import mobLogo from "../assets/images/cc_logo_blue.webp";

import i18n from "../i18n";

const Navigation = ({ t }) => {
  const [expended, setExpended] = useState(false);
  const [lang, setLang] = useState("");
const navigate = useNavigate();

  useEffect(() => {
    setLang(localStorage.getItem("i18nextLng"));
    const enGb = localStorage.getItem("i18nextLng");
    if (enGb === null) {
      setLang("en");
    } else if (enGb === "en-GB" || enGb === "en-US") {
      setLang("en");
    }
    i18n.on("languageChanged init", () => {
      setLang(localStorage.getItem("i18nextLng"));
      const enGb = localStorage.getItem("i18nextLng");
      if (enGb === null) {
        setLang("en");
      } else if (enGb === "en-GB" || enGb === "en-US") {
        setLang("en");
      }
    });
  }, []);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    const language = window.location.pathname.split('/')[2]
     const redirectPath = `/${lng}/${language}`;
     navigate(redirectPath)
    setLang(localStorage.getItem("i18nextLng"));
    setExpended(false);
  };
  const externalUrl = () => {
    window.open("https://www.sharerewards.com/", "_blank");
    setExpended(false);
  };
  return (
    <>
      <div className="container-fluid p-0">
        <nav className="navbar navbar-expand-lg navbar-light desktop-nav">
          <span
            className="humburger d-sm-block d-lg-none ms-3"
            onClick={() => setExpended(true)}
          >
            {" "}
            <img src={hmburger} alt="hm" width={30} />{" "}
          </span>
          <div className="mx-auto d-sm-flex d-block flex-sm-nowrap">
          {lang == "en" && (
            <NavLink className="navbar-brand px-lg-5 px-sm-3 px-1" to={lang + '/'}>
              <img src={logo} alt="logo" height={60} />
            </NavLink>
            )}
          {lang == "ar" && (

            <NavLink className="navbar-brand px-lg-5 px-sm-3 px-1" to={lang + '/'}>
              <img src={arabicLogo} alt="logo" height={60} />
            </NavLink>
          )}
            <div className="collapse navbar-collapse" id="navbarColor03">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item px-xxl-5 px-lg-3 px-sm-3 px-1">
                  <NavLink end className="nav-link" to={lang + "/vote"}>
                    {t("vote")}
                  </NavLink>
                </li>
                <li className="nav-item px-xxl-5 px-lg-3 px-sm-3 px-1">
                  <NavLink end to={lang + "/manifesto"} className="nav-link">
                    {t("manifesto")}
                  </NavLink>
                </li>
                <li className="nav-item px-xxl-5 px-lg-3 px-sm-3 px-1">
                  <NavLink end to={lang + "/broadroom"} className="nav-link">
                    {t("broadroom_table")}
                  </NavLink>
                </li>
                {/* <li className="nav-item px-xxl-5 px-lg-3 px-sm-3 px-1">
                  <NavLink end to="/campaign" className="nav-link">
                    {t('campaign')}
                    
                  </NavLink>
                </li> */}
              </ul>
            </div>
          </div>
        </nav>
      </div>
      {expended && (
        <div className="container-fluid mob-menu d-sm-block d-lg-none">
          <nav className="navbar navbar-expand-lg navbar-light">
            <span className="humburger" onClick={() => setExpended(false)}>
              <img src={closeMenu} alt="hm" width={30} />{" "}
            </span>
            <div className="mx-auto d-sm-flex d-block flex-sm-nowrap">
              <NavLink
                onClick={() => setExpended(false)}
                className="navbar-brand px-lg-5 px-sm-3 px-1"
                to={lang + "/"}
              >
          {lang == "en" && (

                <img src={mobLogo} alt="hm" width={70} />
          )}
          {lang == "ar" && (

<img src={arabicLogoBlue} alt="hm" width={70} />
)}
              </NavLink>
            </div>
            <div className="navbar-collapse mob-sub-menu" id="navbarColor03">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item text-center px-lg-5 px-sm-3 px-1">
                  <NavLink
                    onClick={() => setExpended(false)}
                    end
                    className="nav-link"
                    to={lang + "/vote"}
                  >
                    {t("vote")}
                  </NavLink>
                </li>
                <li className="nav-item px-lg-5 text-center px-sm-3 px-1">
                  <NavLink
                    onClick={() => setExpended(false)}
                    end
                    to={lang + "/manifesto"}
                    className="nav-link"
                  >
                    {t("manifesto")}
                  </NavLink>
                </li>
                <li className="nav-item px-lg-5 text-center px-sm-3 px-1">
                  <NavLink
                    onClick={() => setExpended(false)}
                    end
                    to={lang +"/broadroom"}
                    className="nav-link"
                  >
                    {t("broadroom_table")}
                  </NavLink>
                </li>
                {/* <li className="nav-item px-lg-5 text-center px-sm-3 px-1">
                <NavLink onClick={() => setExpended(false)} end to="/campaign" className="nav-link">
                {t('campaign')}

                </NavLink>
              </li> */}
              </ul>
            </div>
            <hr />
            <div className="navbar-collapse" id="navbarColor04">
              <ul className="navbar-nav mr-auto">

              {lang == "en" && (
                <li className="nav-item justify-content-center nav-footer px-lg-2 px-sm-2 px-1 d-inline-flex">
                  <span className="nav-link ">
                    
                     <span> LANGUAGE: </span>
                   
                  </span>
                  <span
                    className={`nav-link  px-0 pb-0 ${
                      lang === "en" ? "active" : "inactive"
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                     <span>ENGLISH</span>
                  </span>
                  <span
                    className={`nav-link ps-1 pe-0 ${
                      lang === "ar" ? "active" : "inactive"
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    <span>ARABIC</span>
                  </span>
                </li>
              )}

                {lang == "ar" && (
                <li className="nav-item justify-content-center nav-footer px-lg-2 px-sm-2 px-1 d-inline-flex" dir="rtl">
                  <span className="nav-link ">
                    {" "}
                    <span> لغة: </span>
                  </span>
                  <span
                    className={`nav-link  px-0 pb-0 ps-1 ${
                      lang === "en" ? "active" : "inactive"
                    }`}
                    onClick={() => changeLanguage("en")}
                  >
                     <span>إنكليزي</span> 
                  </span>
                  <span
                    className={`nav-link ps-1 pe-0 ${
                      lang === "ar" ? "active" : "inactive"
                    }`}
                    onClick={() => changeLanguage("ar")}
                  >
                    <span>عربي</span>
                  </span>
                </li>
                )}


                <li className="nav-item text-center px-lg-3 px-sm-3 px-1">
                  <NavLink
                    onClick={() => setExpended(false)}
                    end
                    to={lang + "/t&c" }
                    className="nav-link"
                  >
                    {lang == "en" && <span>TERMS & CONDITIONS</span>}
                    {lang == "ar" && <span>الشروط والأحكام</span>}
                  </NavLink>
                </li>
                <li className="nav-item text-center px-lg-3 px-sm-3 px-1">
                  <NavLink
                    onClick={() => setExpended(false)}
                    end
                    to={lang + "/pp" }
                    className="nav-link"
                  >
                    {lang == "en" && <span>PRIVACY POLICY</span>}
                    {lang == "ar" && <span>سياسة الخصوصية</span>}
                  </NavLink>
                </li>
                <li className="nav-item text-center px-lg-3 px-sm-3 px-1">
                  <a onClick={externalUrl} className="nav-link">
                    {lang == "en" && <span>SIGN UP FOR SHARE</span>}
                    {lang == "ar" && <span>"سجلوا في "شير </span>}
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      )}
    </>
  );
};
export default withNamespaces()(Navigation);
