import "./App.css";
import Home from "./pages/Home";
import { Route, Routes, useLocation,useNavigate } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Vote from "./components/vote/Vote";
import Manifesto from "./components/manifesto/Manifesto";
import Campaign from "./components/campagin/Campagin";
import BroadRoom from "./components/broadroom/BroadRoom";
import Layout from "./shared/Layout";
import PP from "./pages/PP";
import TC from "./pages/T&C";
import SignUp from "./pages/SignUp";
import { useEffect } from "react";
import i18n from "./i18n";


function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  function userRedirect(props,component) {
    // console.log(pathname);
    
    // const defaultLang = 'en';
    // const redirectPath = `/${defaultLang}/`;
    // console.log(redirectPath);
    // navigate(redirectPath)
    // props.history.replace({
    //   pathname: redirectPath,
    // });
  
  };
  useEffect(() => {
    if(pathname == '/') {
    const defaultLang = 'en';
    const redirectPath = `/${defaultLang}/`;
    i18n.changeLanguage(defaultLang)

    navigate(redirectPath)
    }
    else {
    const language = window.location.pathname.split('/')[1]
      const getStorage = localStorage.getItem('i18nextLng');
      if(language == 'en' || language == 'ar') {
        if(language != getStorage) {
          i18n.changeLanguage(language)
        }
      }
      else {
        const defaultLang = 'en';
        const redirectPath = `/${defaultLang}/`;
        i18n.changeLanguage(defaultLang)
    
        navigate(redirectPath)
      }
    
    }

  },[])
  return (
    <Layout>
      <Routes>
        <Route exact path="/:lang/*">
          <Route  element={<Home />} />
          <Route path="/vote" element={<Vote />} />
          <Route path="/manifesto" element={<Manifesto />} />
          <Route path="/campaign" element={<Campaign />} />
          <Route path="/broadroom" element={<BroadRoom />} />
          <Route path="/pp" element={<PP />} />
          <Route path="/t&c" element={<TC />} />
          <Route path="/share" element={<SignUp />} />
        <Route path="*" element={<NotFound />}   />

        </Route>

        <Route path="*" element={<NotFound />}   />
      </Routes>
    </Layout>
  );
}

export default App;
