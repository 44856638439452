import { useState,useEffect } from "react";
import i18n from "../i18n";
import ReactSnapPixel from 'react-snapchat-pixel';

const PP = () => {
  const [lang,setLang] = useState('');
  useEffect(() => {
    setLang(localStorage.getItem("i18nextLng"));
    const enGb = localStorage.getItem("i18nextLng");
    if (enGb === null) {
      setLang("en");
    } else if (enGb === "en-GB" || enGb === "en-US") {
      setLang("en");
    }

    i18n.on("languageChanged init", () => {
      setLang(localStorage.getItem("i18nextLng"));
      const enGb = localStorage.getItem("i18nextLng");
      if (enGb === null) {
        setLang("en");
      } else if (enGb === "en-GB" || enGb === "en-US") {
        setLang("en");
      }
    });
  }, []);
  useEffect(() => {
    const userIdentification = { user_email: 'some@email.com' }; // optional
    const options = {
        debug: false, 		// enable logs
    };
    ReactSnapPixel.init('0b100a5f-a529-4761-9b8e-ec3387bc1625', userIdentification, options);
     
    ReactSnapPixel.pageView(); 	
  
  }, [])
  document.body.classList.remove('home');

  return (
    <>
    {lang == 'en' &&(
      <div className="content-desc-section mt-5">
        <div className="content-description">
        <h1>Privacy Policy</h1>
          <p className="mt-5">
            <span >
              Majid&nbsp;Al&nbsp;Futtaim Holding is a limited liability company
              incorporated in the Emirate of Dubai, United Arab Emirates.
              Majid&nbsp;Al&nbsp;Futtaim Holding operates through various
              subsidiaries including Majid&nbsp;Al&nbsp;Futtaim Retail,
              Majid&nbsp;Al&nbsp;Futtaim Properties, and
              Majid&nbsp;Al&nbsp;Futtaim Ventures (
              <strong>
                “Majid&nbsp;Al&nbsp;Futtaim”, “we”, “us”,&nbsp;“our”&nbsp;
              </strong>
              ).
            </span>
          </p>
          <p>
            <span >
              This policy describes how Majid&nbsp;Al&nbsp;Futtaim collects,
              processes uses and discloses your personal information as a data
              controller, when you contact us, use our services or interact with
              our platforms including websites and mobile
              apps&nbsp;(together&nbsp;<strong>"Platforms"</strong>).
            </span>
          </p>
          <p>
            <strong>
              <span >WHAT THIS POLICY&nbsp;COVERS?</span>
            </strong>
          </p>
          <p>
            <span >
              We are committed to adopting the highest standards when it comes
              to how we collect, use and protect your personal information, and
              have accordingly developed this privacy policy (the&nbsp;
              <strong>“Policy”&nbsp;</strong>),&nbsp;which:
            </span>
          </p>
          <ul >
            <li >
              <span>
                Sets out the types of personal information we collect;
              </span>
            </li>
            <li >
              <span>
                Explains how and why we collect and use your personal
                information
              </span>
            </li>
            <li >
              <span>
                Explains when and why we may share personal information within
                Majid&nbsp;Al&nbsp;Futtaim and with other organisations;
              </span>
            </li>
            <li >
              <span>
                Explains how we protect the personal information we collect; and
              </span>
            </li>
            <li >
              <span>
                Explains the rights and choices you have in relation to your
                personal information
              </span>
            </li>
          </ul>
          <p>
            <span >&nbsp;</span>
          </p>
          <p>
            <span >
              This Policy applies if you use our services (referred to in this
              Policy as “<strong>our Services</strong>”). Using our
              Services&nbsp;means:
            </span>
          </p>
          <ul >
            <li >
              <span>
                Buying / Ordering services or products from any of our
                subsidiaries over the phone, in store, or online
              </span>
            </li>
            <li >
              <span>
                Using any of our platforms, including websites (“
                <strong>our Websites</strong>”), public Wi-Fi networks, or
                mobile applications (
                <strong>&nbsp;“our Mobile Apps”&nbsp;</strong>); or
              </span>
            </li>
            <li >
              <span>
                Being a member of any of our loyalty schemes (e.g. MyClub)
              </span>
            </li>
            <li >
              <span>
                Taking part in our promotions &amp; competitions and registering
                to receive our newsletters and offers
              </span>
            </li>
          </ul>
          <p>
            <span >
              This Policy also applies if you contact us or we contact you about
              our&nbsp;Services.
            </span>
          </p>
          <p>
            <strong>
              <span >
                PERSONAL INFORMATION WE&nbsp;COLLECT
              </span>
            </strong>
          </p>
          <p>
            <strong>
              <span >
                When you register to our services, we may&nbsp;collect:
              </span>
            </strong>
          </p>
          <ul >
            <li >
              <span>
                Your personal details, including your postal and billing
                addresses, email address(es), phone number(s), date of birth,
                gender, and passport or ID number
              </span>
            </li>
            <li >
              <span>
                Your account login details, such as your username and the
                password (encrypted) that you have chosen; and
              </span>
            </li>
            <li >
              <span>
                Your interests, preferences, income, and other profiling
                information.
              </span>
            </li>
          </ul>
          <p>
            <strong>
              <span >
                When you shop with us online, browse our Websites, use our
                Mobile Apps, or connect to Wi-Fi networks we provide, we
                may&nbsp;collect:
              </span>
            </strong>
          </p>
          <ul >
            <li >
              <span>
                Information about your online purchases (for example: what,
                when, where you ordered / bought and how you paid);
              </span>
            </li>
            <li >
              <span>
                Information about your online browsing behaviour on our
                Websites, Mobile Apps and public networks and information about
                when you click on one of our adverts (including those shown on
                other organisations’ websites);
              </span>
            </li>
            <li >
              <span>
                Information about devices you have used to access our Services
                (including the make, model and operating system, IP address,
                browser type and mobile device identifiers); and
              </span>
            </li>
            <li >
              <span>Information about your precise geolocation.</span>
            </li>
          </ul>
          <p>
            <strong>
              <span >
                When you use any of our loyalty program(s) to shop with us, we
                may&nbsp;collect:
              </span>
            </strong>
          </p>
          <ul >
            <li >
              <span>
                Transaction information, including the in-store and online
                purchases you earn points for and how you use your points,
                coupons, and vouchers within our subsidiaries or with other
                partners.
              </span>
            </li>
          </ul>
          <p>
            <span >&nbsp;</span>
          </p>
          <p>
            <strong>
              <span >
                When you contact us or we contact you or you take part in
                promotions, competitions, surveys or questionnaires about our
                Services, we may&nbsp;collect:
              </span>
            </strong>
          </p>
          <ul >
            <li >
              <span>Personal information you provide about yourself;</span>
            </li>
            <li >
              <span>
                Details of emails and other digital communications we send to
                you including information about the emails you open and links in
                them that you click on; and
              </span>
            </li>
            <li >
              <span>
                Your feedback and contributions to customer surveys and
                questionnaires.
              </span>
            </li>
          </ul>
          <p>
            <strong>
              <span >
                Other sources of personal&nbsp;information
              </span>
            </strong>
          </p>
          <p>
            <span >
              We may also use personal information from other sources, such as
              specialist companies that supply information, online media
              channels, our partners and public registers. We may collect the
              following types of personal information about you from other
              sources: contact details, credit history, purchases, interests,
              preferences, or other types of publicly available information.
              This other personal information helps us&nbsp;to:
            </span>
          </p>
          <ul >
            <li >
              <span>
                review and improve the accuracy of the data we hold; and
              </span>
            </li>
            <li >
              <span>
                improve and measure the effectiveness of our marketing
                communications, including online advertising.
              </span>
            </li>
          </ul>
          <p>
            <span >
              We may be required by law to collect personal information about
              you or as a consequence of any contractual relationship we have
              with&nbsp;you.
            </span>
          </p>
          <p>
            <span >
              If you choose not to provide your personal information to be used
              in accordance with this Policy, you will still be able to visit
              our Platforms but you may not be able to access certain options,
              products or services, and in some cases we may not be able to
              fulfill a service you&nbsp;request.
            </span>
          </p>
          <p>
            <strong>
              <span >
                WHY AND HOW WE USE YOUR PERSONAL&nbsp;INFORMATION
              </span>
            </strong>
          </p>
          <p>
            <strong>
              <span >
                Make our Services available to&nbsp;you
              </span>
            </strong>
          </p>
          <p>
            <span >
              We use your personal information to provide the services you have
              requested,&nbsp;including:
            </span>
          </p>
          <ul >
            <li >
              <span>Processing your orders and refunds; and</span>
            </li>
            <li >
              <span>Managing the accounts you hold with us.</span>
            </li>
          </ul>
          <p>
            <span >
              This is so we can provide the services to you in line with our
              contractual obligations to&nbsp;you.
            </span>
          </p>
          <p>
            <strong>
              <span >
                Personalise your&nbsp;experience
              </span>
            </strong>
          </p>
          <p>
            We may use your personal information to personalise the marketing
            messages we send to you and to make them more relevant and
            interesting, as this is in our legitimate business interests. Where
            necessary we will obtain your consent first. We may use your
            personal information for this purpose in the following&nbsp;ways:
          </p>
          <ul >
            <li >
              <span>
                Using your online browsing behaviour as well as your in-store
                and online purchases to help us better understand you as a
                customer and provide you with personalised offers and services;
              </span>
            </li>
            <li >
              <span>
                Providing you with relevant marketing communications (including
                by email, post, telephone, SMS, or online advertising), relating
                to products and services we offer, and those of our suppliers
                and partners. As part of this, online advertising may be
                displayed on Our Websites and on other organisations’ websites
                and online media channels. We may also measure the effectiveness
                of our marketing communications and those of our suppliers and
                partners;
              </span>
            </li>
            <li >
              <span>
                To help us to better understand you and provide you with
                services and marketing communications, that are relevant to your
                interests, we combine personal information we collect when you
                make purchases in-store with personal information collected from
                our Websites, Mobile Apps and other sources including the
                different subsidiaries under Majid&nbsp;Al&nbsp;Futtaim.
              </span>
            </li>
          </ul>
          <p >
            <span >&nbsp;</span>
          </p>
          <p>
            <strong>
              <span >
                Contact and interact with&nbsp;you
              </span>
            </strong>
          </p>
          <p>
            We may use your personal information when we communicate with you,
            in order&nbsp;to:
          </p>
          <ul >
            <li >
              <span>
                Contact you about our Services, for example by phone, email or
                post or by responding to social media posts that you have
                directed at us;
              </span>
            </li>
            <li >
              <span>
                Manage promotions and competitions you take part in, including
                those we run with our suppliers and partners;
              </span>
            </li>
            <li >
              <span>
                Invite you to take part in and manage customer surveys,
                questionnaires and other market research activities carried out
                by Majid&nbsp;Al&nbsp;Futtaim and by other organisations on our
                behalf
              </span>
            </li>
          </ul>
          <p>
            <span >
              It is in our legitimate business interests that we are able to
              provide you with appropriate responses and provide you with notice
              about our&nbsp;services.
            </span>
          </p>
          <p>
            <strong>
              <span >
                Manage and improve our day-to-day&nbsp;operations
              </span>
            </strong>
          </p>
          <p>
            <span >
              <strong>
                We analyse information about how you use our services&nbsp;to:
              </strong>
            </span>
          </p>
          <ul >
            <li >
              <span>Manage and improve our Websites and Mobile Apps;</span>
            </li>
            <li >
              <span>
                Help to develop and improve our product range, services, stores,
                information technology systems, know-how and the way we
                communicate with you;
              </span>
            </li>
            <li >
              <span>Detect and prevent fraud or other crime.</span>
            </li>
          </ul>
          <p>
            <span >
              It is in our legitimate business interests to use the information
              provided to us for this purpose, so we can understand any issues
              with our services to improve&nbsp;it.
            </span>
          </p>
          <p>
            <strong>
              <span >
                SHARING PERSONAL&nbsp;INFORMATION
              </span>
            </strong>
          </p>
          <p>
            <span >
              We may share your personal information with third parties under
              the following&nbsp;circumstances:
            </span>
          </p>
          <p>
            <strong>
              <span >
                Majid&nbsp;Al&nbsp;Futtaim&nbsp;Subsidiaries
              </span>
            </strong>
          </p>
          <p>
            <span >
              We may share the personal information collected by any of our
              subsidiaries with our other subsidiaries for the purposes
              listed&nbsp;above.
            </span>
          </p>
          <p>
            <strong>
              <span >Retail&nbsp;Partners</span>
            </strong>
          </p>
          <p>
            <span >
              We may share your personal information with other companies that
              sell products through our Services ("
              <strong>Retail Partners</strong>"). We only share personal
              information that enables our Retail Partners to provide their
              services. For example, when you shop online or in-store we will
              give the relevant Retail Partner your name, contact details, and
              address so that they can deliver your&nbsp;items.
            </span>
          </p>
          <p>
            <strong>
              <span >Service&nbsp;Providers</span>
            </strong>
          </p>
          <p>
            <span >
              We work with carefully selected Service Providers that carry out
              certain functions on our behalf. These include, for example,
              companies that help us with technology services, storing and
              combining data, processing payments and delivering orders. We only
              share personal information that enables our Service Providers to
              provide their&nbsp;services.
            </span>
          </p>
          <p>
            <span >
              Some of the Service Providers we work with operate online media
              channels, and they place relevant online advertising for our
              products and services, as well as those of our suppliers and our
              Retail Partners, on those online media channels on our behalf. For
              example, you may see an advert for our products and services when
              you use a particular social media&nbsp;site.
            </span>
          </p>
          <p>
            <strong>
              <span >
                SHARING PERSONAL INFORMATION WITH OTHER&nbsp;ORGANISATIONS
              </span>
            </strong>
          </p>
          <p>
            <span >
              We may share personal information with other organisations in the
              following&nbsp;circumstances:
            </span>
          </p>
          <ul >
            <li >
              <span>
                if any applicable law or a public authority says we must share
                the personal information;
              </span>
            </li>
            <li >
              <span>
                if we need to share personal information in order to establish,
                exercise or defend our legal rights (this includes providing
                personal information to others for the purposes of preventing
                fraud and reducing credit risk);
              </span>
            </li>
            <li >
              <span>
                to an organisation we sell or transfer (or enter into
                negotiations to sell or transfer) any of our businesses or any
                of our rights or obligations under any agreement we may have
                with you to. If the transfer or sale goes ahead, the
                organisation receiving your personal information can use your
                personal information in the same way as us
              </span>
            </li>
            <li >
              <span>to any other successors in title to our business; or</span>
            </li>
            <li >
              <span>
                to anyone to whom we transfer or may transfer our rights and
                duties under our agreement with you.
              </span>
            </li>
          </ul>
          <p >
            <span >&nbsp;</span>
          </p>
          <p>
            <strong>
              <span >
                TRANSFER OF YOUR PERSONAL&nbsp;INFORMATION
              </span>
            </strong>
          </p>
          <p>
            <span >
              Your personal information may be transferred to, stored, and
              processed in a country that is different from your country of
              residence or our country of incorporation. In any case, we have
              put in place appropriate safeguards in accordance with applicable
              legal requirements to ensure that your data is
              adequately&nbsp;protected.
            </span>
          </p>
          <p>
            <span >&nbsp;</span>
          </p>
          <p>
            <span >
              In the event your personal information is transferred to a foreign
              jurisdiction, it may be subject to the laws of that jurisdiction
              and we may be required to disclose it to the courts, law
              enforcement or governmental authorities in those jurisdictions but
              we will only do so where required by applicable&nbsp;laws.
            </span>
          </p>
          <p>
            <strong>
              <span >
                HOW WE PROTECT PERSONAL&nbsp;INFORMATION
              </span>
            </strong>
          </p>
          <p>
            <span >
              We maintain physical, technical, and organisational safeguards to
              secure your personal information from unauthorized access, use,
              alteration and destruction. However, no internet-based site can be
              100% secure and we cannot be held responsible for unauthorised or
              unintended access that is beyond our&nbsp;control.
            </span>
          </p>
          <p>
            <strong>
              <span >SOCIAL&nbsp;MEDIA</span>
            </strong>
          </p>
          <p>
            <span >
              We operate channels, pages and accounts on some social media sites
              to inform, assist and engage with customers. We monitor and record
              comments and posts made on these channels about us so that we can
              improve our&nbsp;services.
            </span>
          </p>
          <p>
            <span >
              Majid&nbsp;Al&nbsp;Futtaim is not responsible for any information
              posted on those sites other than information we have posted
              ourselves. We do not endorse the social media sites themselves or
              any information posted on them by third&nbsp;parties.
            </span>
          </p>
          <p>
            <strong>
              <span >RETENTION</span>
            </strong>
          </p>
          <p>
            <span >
              We keep records for as long as required to a) manage purchases,
              bookings, memberships and provide the other relevant products or
              services anticipated by this Privacy Policy, including keeping you
              up-to-date with our marketing b) personalise the products,
              services and communications with you c) comply with the applicable
              record retention legal requirements. When deciding how long to
              keep your personal information after our relationship with you has
              ended, we take into account our legal obligations and
              regulators'&nbsp;expectations.
            </span>
          </p>
          <p>
            <span >
              If you wish to request that we no longer use your information to
              provide you services, contact us at Analytics Office,
              Majid&nbsp;Al&nbsp;Futtaim Management Services, P.O. Box 91100,
              Dubai, United Arab Emirates. You can also email us at&nbsp;
              <a href="mailto:privacy@maf.ae">
                <span >
                  privacy@maf.ae
                </span>
              </a>
              .
            </span>
          </p>
          <p>
            <span >
              However, we will retain and use your registration information as
              necessary to comply with our legal obligations, resolve disputes,
              and enforce our&nbsp;agreements.
            </span>
          </p>
          <p>
            <strong>
              <span >MARKETING&nbsp;COMMUNICATIONS</span>
            </strong>
          </p>
          <p>
            <span >
              All marketing communications we send to you will provide you with
              a way to withdraw your consent to future marketing. If you no
              longer wish to receive promotional materials you may opt-out from
              receiving these communications or change your account settings,
              this will remove you from marketing&nbsp;lists.
            </span>
          </p>
          <p>
            <span >
              Please note that if you unsubscribe from marketing communications
              you will still receive operational and service messages from us
              regarding the services or products you bought from us and
              responses to enquiries made to us, and that we may hold your
              details on a suppression list so we don't send you marketing
              communications in the&nbsp;future.
            </span>
          </p>
          <p>
            <strong>
              <span >
                COOKIES AND SIMILAR&nbsp;TECHNOLOGIES
              </span>
            </strong>
          </p>
          <p>
            <span >
              We use cookies and similar technologies, such as tags and pixels
              (“Cookies”), to personalise and improve your customer experience
              as you use our Websites and Mobile Apps and to provide you with
              relevant online advertising. Cookies are small data files that
              allow a website or Mobile App to collect and store a range of data
              on your desktop computer, laptop or mobile&nbsp;device.
            </span>
          </p>
          <p>
            <span >
              Cookies help us to provide important features and functionality on
              our Websites and Mobile Apps, and we use them to improve your
              customer experience. For example, we use Cookies to do
              the&nbsp;following:
            </span>
          </p>
          <p>
            <strong>
              <span >
                Improve the way our Websites and Mobile Apps&nbsp;work
              </span>
            </strong>
          </p>
          <p>
            <span >
              Cookies allow us to improve the way our Websites and Mobile Apps
              work so that we can personalise your experience and allow you to
              use many of their useful features. For example, we use Cookies so
              we can remember your preferences and the contents of your shopping
              basket when you return to our Websites and Mobile&nbsp;Apps.
            </span>
          </p>
          <p>
            <strong>
              <span >
                Improve the performance of our Websites and Mobile&nbsp;Apps
              </span>
            </strong>
          </p>
          <p>
            <span >
              Cookies can help us to understand how our Websites and Mobile Apps
              are being used, for example, by telling us if you get an error
              messages as you browse. These Cookies collect data that is mostly
              aggregated and&nbsp;anonymous.
            </span>
          </p>
          <p>
            <strong>
              <span >
                Deliver relevant online&nbsp;advertising
              </span>
            </strong>
          </p>
          <p>
            <span >
              We use Cookies to help us deliver online advertising that we
              believe is most relevant to you on our Websites and other
              organisations’&nbsp;websites.
            </span>
          </p>
          <p>
            <span >
              Cookies used for this purpose are often placed on our Websites by
              other organisations, and always with our permission. These Cookies
              may collect information about your online behaviour, such as your
              IP address, the website you arrived from and information about
              your purchase history or the content of your shopping basket. This
              means that you may see our adverts on our Websites and on other
              organisations’ websites. You may also see adverts for other
              organisations on our&nbsp;Websites.
            </span>
          </p>
          <p>
            <span >
              To help us to deliver online advertising that is relevant to you,
              we may also combine data we collect through Cookies in the browser
              of your desktop computer or other devices with other data that we
              have collected (e.g. Loyalty&nbsp;program).
            </span>
          </p>
          <p>
            <strong>
              <span >
                Measuring the effectiveness of our marketing communications,
                including online&nbsp;advertising
              </span>
            </strong>
          </p>
          <p>
            <span >
              Cookies can tell us if you have seen a specific advert, and how
              long it has been since you have seen it. This information allows
              us to measure the effectiveness of our online advertising
              campaigns and control the number of times you are shown
              an&nbsp;advert.
            </span>
          </p>
          <p>
            <span >
              We also use Cookies to measure the effectiveness of our marketing
              communications, for example by telling us if you have opened a
              marketing email that we have sent&nbsp;you.
            </span>
          </p>
          <p>
            <strong>
              <span >
                Your choices when it comes to&nbsp;Cookies
              </span>
            </strong>
          </p>
          <p>
            <span >
              You can use your browser settings to accept or reject new Cookies
              and to delete existing Cookies. You can also set your browser to
              notify you each time new Cookies are placed on your computer or
              other&nbsp;device.
            </span>
          </p>
          <p>
            <span >
              If you choose to disable some or all Cookies, you may not be able
              to make full use of our platforms. For example, you may not be
              able to add items to your shopping basket, proceed to checkout, or
              use some of our products and services that require you to
              sign&nbsp;in.
            </span>
          </p>
          <p>
            <span >
              Once you have given us your consent to the use of cookies, we
              shall store cookies on your computer or device. If you wish to
              withdraw your consent at any time, you will need to delete our
              cookies using your internet browser&nbsp;settings.
            </span>
          </p>
          <p>
            <strong>
              <span >
                AGGREGATE DATA, DATA ANALYTICS AND SOCIAL
                MEDIA&nbsp;FUNCTIONALITY
              </span>
            </strong>
          </p>
          <p>
            <span >
              We may aggregate personal information and remove any identifying
              elements in order to analyse patterns and improve our marketing
              and promotional efforts, to analyse Platform use, to improve our
              content and product offerings, and to customize our Platforms’
              content, layout, products and services, and we may appoint third
              parties to do this on our behalf, as set out&nbsp;above.
            </span>
          </p>
          <p>
            <span >
              We gather certain usage information like the number and frequency
              of visitors to our Platforms. This information may include which
              webpage you just came from, which webpage you next go to, what
              browser you are using, your device and your IP address. We only
              use such data in the aggregate. This collective data helps us to
              determine how much our customers use parts of our Platforms, and
              do research on our users’ demographics, interests, and behaviour
              to better understand and serve&nbsp;you.
            </span>
          </p>
          <p>
            <span >
              If you use buttons on our Platforms linked to social media or
              similar sites (for example "Like" and/or "Share" buttons), content
              from our Platforms may be sent back to that other website or
              service and, depending on your privacy settings, may be privately
              or publicly visible (for example to friends, followers, or
              generally to anyone who has access to your profile&nbsp;page).
            </span>
          </p>
          <p>
            <strong>
              <span >YOUR&nbsp;RIGHTS</span>
            </strong>
          </p>
          <p>
            <span >
              You have the right to see or access the personal information we
              hold about you. To receive a copy, please write to the Analytics
              Office, Majid&nbsp;Al&nbsp;Futtaim Management Services, P.O. Box
              91100, Dubai, United Arab Emirates. You can also email us at&nbsp;
              <a href="mailto:privacy@maf.ae">
                <span >
                  privacy@maf.ae
                </span>
              </a>
              <a href="mailto:privacy@maf.ae">
                <span >
                  .
                </span>
              </a>
            </span>
          </p>
          <p>
            <span >&nbsp;</span>
          </p>
          <p>
            <span >
              In addition, you have certain rights regarding your personal
              information, subject to local law. These include the following
              rights&nbsp;to:
            </span>
          </p>
          <ul >
            <li >
              <span>rectify the information we hold about you;</span>
            </li>
            <li >
              <span>erase your personal information;</span>
            </li>
            <li >
              <span>restrict our use of your personal information;</span>
            </li>
            <li >
              <span>object to our use of your personal information;</span>
            </li>
            <li >
              <span>
                withdraw any consents you have provided for our use of your
                personal information;
              </span>
            </li>
            <li >
              <span>
                receive your personal information in a usable electronic format
                and transmit it to a third party (right to data portability);
                and
              </span>
            </li>
            <li >
              <span>
                lodge a complaint with your local data protection authority.
              </span>
            </li>
          </ul>
          <p>
            <span >
              If you would like to discuss or exercise such rights, please
              contact us at the details&nbsp;below.
            </span>
          </p>
          <p>
            <span >
              If you are concerned that any of the information we hold about you
              is incorrect, or out of date, please write to the address above to
              report your concerns and we will take appropriate steps to amend
              our&nbsp;records.
            </span>
          </p>
          <p>
            <span >
              Please note that we will likely require additional information
              from you in order to honour your&nbsp;requests.
            </span>
          </p>
          <p>
            <strong>
              <span >PRIVACY&nbsp;CONCERNS</span>
            </strong>
          </p>
          <p>
            <span >
              If you have any concerns about our compliance with applicable
              privacy laws or this Privacy Policy please write to the Corporate
              Compliance Office, Majid&nbsp;Al&nbsp;Futtaim Management Services,
              P.O. Box 91100, Abu Dhabi, United Arab Emirates or email us
              at&nbsp;
              <a href="mailto:privacy@maf.ae">
                <span >
                  privacy@maf.ae
                </span>
              </a>
              . We will endeavour to respond to your concerns as soon
              as&nbsp;possible.
            </span>
          </p>
          <p>
            <strong>
              <span >
                NOTIFICATION OF CHANGES TO THIS PRIVACY&nbsp;POLICY
              </span>
            </strong>
          </p>
          <p>
            <span >
              We update this Privacy Policy when needed and place updates on our
              platforms. If we are going to use your personal information in a
              manner different from that stated at the time of collection, we
              will notify you. All changes will become effective when posted
              unless indicated otherwise. Please review this Privacy Policy
              periodically for&nbsp;changes.
            </span>
          </p>
          <p>
            <strong>
              <span >LINKS</span>
            </strong>
          </p>
          <p>
            <span >
              Our Websites or Mobile Apps may contain links to other websites
              operated by other organisations that have their own privacy
              policies. Please make sure you read the terms and conditions and
              privacy policy carefully before providing any personal information
              on a website as we do not accept any responsibility or liability
              for websites of other&nbsp;organisations.
            </span>
          </p>
          <p>
            <strong>
              <span >CHILDREN</span>
            </strong>
          </p>
          <p>
            <span >
              Our platforms are not directed towards children. If you are
              providing personal information for an individual less than 13
              years of age, you are providing us your affirmative parental
              consent as the legal parent or guardian to collect, use and
              process the information of the individual less than 13 years of
              age, consistent with this Privacy&nbsp;Policy.
            </span>
          </p>
        
          <p>&nbsp;</p>
        </div>
      </div>
)}
{lang == 'ar' &&(


      <div className="content-desc-section mt-5" dir="rtl">
   <div className="content-description">
   <h1 className="mt-5" >سياسة الخصوصية</h1>

      <p>إن شركة ماجد&nbsp;الفطيم القابضة هي شركة ذات مسؤولية محدودة تأسست في إمارة دبي، الإمارات العربية المتحدة. وتعمل هذه الشركة عبر شركات تابعة مختلفة منها شركة ماجد&nbsp;الفطيم للتجزئة وشركة ماجد&nbsp;الفطيم العقارية وشركة ماجد&nbsp;الفطيم للمشاريع (ويُشار إليها مجتمعةً في ما يلي بـ<strong>"شركة ماجد&nbsp;الفطيم" أو "نحن" أو "لنا" أو&nbsp;"نا"</strong>).</p>
      <p>وتصف هذه السياسة كيف تجمع شركة ماجد&nbsp;الفطيم معلوماتك الشخصية وتعالجها وتستخدمها وتفصح عنها بصفتها مراقباً للبيانات، وذلك عند اتصالك بنا أو استفادتك من خدماتنا أو تفاعلك مع منصاتنا بما فيها المواقع الإلكترونية وتطبيقات الهاتف المتحرك (يُشار إليها مجتمعةً&nbsp;بـ<strong>"المنصات"</strong>).</p>
      <p><strong> ماذا تغطي هذه&nbsp;السياسة؟</strong></p>
      <p>نحن ملتزمون باعتماد أعلى المعايير في ما يتعلق بكيفية جمع معلوماتك الشخصية واستخدامها وحمايتها، وبناءً عليه وضعنا سياسة الخصوصية هذه ("السياسة")&nbsp;التي:</p>
      <ul >
         <li ><span>تحدد أنواع المعلومات الشخصية التي نجمعها؛</span></li>
         <li ><span>تشرح طرق وأسباب جمعنا واستخدامنا لمعلوماتك الشخصية</span></li>
         <li ><span>تبيّن حالات وأسباب التبادل المحتمل لمعلوماتك الشخصية داخل شركة ماجد&nbsp;الفطيم ومع مؤسسات أخرى؛</span></li>
         <li ><span>تشرح كيف نحمي المعلومات الشخصية التي نجمعها؛</span></li>
         <li ><span>توضح الحقوق والخيارات المتاحة لك في ما يتعلق بمعلوماتك الشخصية</span></li>
      </ul>
      <p>وتنطبق هذه السياسة في حال استفادتك من خدماتنا (المشار إليها في هذه السياسة بـ<strong>"خدماتنا"</strong>). وتعني الاستفادة من&nbsp;خدماتنا:</p>
      <ul >
         <li ><span>شراء / طلب الحصول على الخدمات أو المنتجات من أي شركة من شركاتنا التابعة عبر الهاتف أو الإنترنت أو في المتجر</span></li>
         <li ><span>استخدام أي منصة من منصاتنا، بما فيها المواقع الإلكترونية (<strong>"مواقعنا الإلكترونية"</strong>) أو شبكات "الواي فاي" العامة أو تطبيقات الهاتف المتحرك (<strong>"تطبيقاتنا على الهاتف المتحرك"</strong>)؛ أو</span></li>
         <li ><span>الانضمام إلى أي من برامج الولاء لدينا (مثل برنامج ماي كلوب)</span></li>
         <li ><span>المشاركة في حملاتنا الترويجية ومسابقاتنا والتسجيل للحصول على رسائلنا الإخبارية وعروضناكما تنطبق هذه السياسة إذا اتصلت بنا أو اتصلنا بك بشأن خدماتن</span></li>
         <p>&nbsp;</p>
         <p><strong> </strong>المعلومات الشخصية التي نجمعها&nbsp;عند قيامك بالتسجيل للاستفادة من خدماتنا، قد نعمد إلى&nbsp;جمع:</p>
         <ul >
            <li ><span> تفاصيلك الشخصية، بما فيها العناوين البريدية وعناوين الفواتير وعنوان (عناوين) البريد الإلكتروني ورقم (أرقام) الهاتف وتاريخ الولادة   والجنس، ورقم جواز السفر أو بطاقة الهوية</span></li>
            <li ><span> معلومات تسجيل الدخول إلى حسابك مثل اسم المستخدم وكلمة المرور (المشفّرة) التي اخترتها؛</span></li>
            <li ><span>اهتماماتك وتفضيلاتك ودخلك ومعلومات أخرى تخصّك</span></li>
         </ul>
         <p><strong> عندما تتسوّق معنا عبر الإنترنت، أو تستعرض مواقعنا الإلكترونية أو تستخدم تطبيقاتنا للهاتف المتحرك أو تتصل بشبكات "الواي فاي" التي نوفرها، قد&nbsp;نجمع:</strong></p>
         <ul >
            <li ><span>معلومات حول مشترياتك عبر الإنترنت (على سبيل المثال، الأشياء التي طلبت شراءها / اشتريتها ومتى وأين اشتريتها وكيف دفعت)؛</span></li>
            <li ><span> معلومات عن سلوكك في التصفح الرقمي على مواقعنا الإلكترونية وتطبيقاتنا للهاتف المتحرك والشبكات العامة ومعلومات عن أوقات  الضغط على أحد إعلاناتنا (بما فيها الإعلانات التي تظهر على مواقع إلكترونية لمؤسسات أخرى)؛</span></li>
            <li ><span>معلومات عن الأجهزة التي استخدمتها للوصول إلى خدماتنا (بما فيها النوع والطراز ونظام التشغيل، وعنوان بروتوكول الإنترنت، ونوع المتصفح ومعرفات الأجهزة المحمولة)؛</span></li>
            <li ><span>ومعلومات عن موقعك الجغرافي المحدد</span></li>
         </ul>
         <p><strong> عندما تستخدم أي برنامج من برامج الولاء لدينا للتسوّق معنا، قد نقوم&nbsp;بجمع:</strong></p>
         <ul >
            <li ><span>معلومات عن المعاملات تشمل المشتريات في المتاجر وعبر الإنترنت التي تكسب نقاطاً من خلالها وكيفية استخدامك للنقاط، والكوبونات والقسائم داخل شركاتنا التابعة أو مع شركاء آخرين</span></li>
         </ul>
         <p><strong></strong></p>
         <p><strong>عندما تتصل بنا أو نتصل بك أو تشارك في الحملات الترويجية أو المسابقات أو الاستبيانات أو الاستطلاعات بشأن خدماتنا، قد&nbsp;نجمع:</strong></p>
         <ul >
            <li ><span>معلومات شخصية توفرها عن نفسك؛</span></li>
            <li ><span>تفاصيل عن رسائل البريد الإلكتروني ورسائل رقمية أخرى نرسلها إليك بما فيها معلومات عن رسائل البريد الإلكتروني التي تفتحها والروابط التي تضغط عليها ضمنها؛</span></li>
            <li ><span>ملاحظاتك ومساهماتك في اسبيانات واستطلاعات العملاء</span></li>
         </ul>
         <p><strong> مصادر أخرى للمعلومات&nbsp;الشخصية</strong></p>
         <p>قد نستخدم أيضاً المعلومات الشخصية من مصادر أخرى مثل الشركات المتخصصة التي توفر المعلومات، وقنوات الإعلام الرقمي وشركائنا والسجلات العامة. كما يجوز لنا أن نجمع الأنواع التالية لمعلوماتك الشخصية من مصادر أخرى: تفاصيل الاتصال، السجل الائتماني، المشتريات، الاهتمامات، التفضيلات، أو أنواع أخرى من المعلومات المتاحة للعموم. وتساعدنا هذه المعلومات الشخصية الأخرى&nbsp;في:</p>
         <ul >
            <li ><span>مراجعة وتحسين دقة البيانات لدينا؛</span></li>
            <li ><span>وتحسين وقياس مدى فعالية رسائلنا التسويقية بما فيها الإعلانات الرقمية</span></li>
         </ul>
         <p>وقد يُطلب إلينا قانوناً جمع معلومات شخصية عنك أو نتيجة أي علاقة تعاقدية نقيمها معك.وإذا اخترت عدم توفير معلوماتك الشخصية لاستخدمها وفق هذه السياسة، ستتمكن مع ذلك من زيارة منصاتنا لكنك قد لا تستطيع الحصول على خيارات أو منتجات أو خدمات معينة، وفي بعض الحالات قد لا نتمكن من تقديم خدمة&nbsp;تطلبها.</p>
         <p><strong> لماذا وكيف نستخدم معلوماتك&nbsp;الشخصية؟</strong></p>
         <p><strong> توفير خدماتنا&nbsp;لك</strong></p>
         <p>نستخدم معلوماتك الشخصية لتقديم الخدمات التي طلبتها، بما&nbsp;فيها:</p>
         <ul >
            <li ><span>تلبية الطلبات والمستردات الخاصة بك؛</span></li>
            <li ><span>وإدارة حساباتك لدينا وبذلك يمكننا تقديم الخدمات إليك وفقاً لموجباتنا التعاقدية تجاهك.</span></li>
         </ul>
         <p><strong></strong></p>
         <p><strong>إضفاء طابع شخصي على&nbsp;تجربتك</strong></p>
         <p>يجوز أن نستخدم معلوماتك الشخصية لإضفاء طابع شخصي على رسائل التسويق التي نرسلها إليك وجعلها أكثر ملاءمة وإفادة على اعتبار ذلك يصب في مصالحنا التجارية المشروعة. وسنحصل على موافقتك أولاً عند الضرورة. ويجوز أن نستخدم معلوماتك الشخصية لهذا الغرض بالطرق&nbsp;التالية:</p>
         <ul >
            <li ><span>الاستفادة من سلوكك في التصفح الرقمي ومشترياتك داخل المتجر أو عبر الإنترنت لنفهمك بشكل أفضل كعميل ونزوّدك بعروض وخدمات ذات طابع شخصي؛</span></li>
            <li ><span>تزويدك برسائل التسويق الملائمة (عبر البريد الإلكتروني أو البريد العادي أو الهاتف أو الرسائل القصيرة أو الإعلانات الرقمية أو وسائل أخرى) في ما يتعلق بالمنتجات والخدمات التي نوفرها والمنتجات والخدمات المتوفرة لدى موردينا وشركائنا. وفي الإطار عينه، يجوز عرض الإعلانات الرقمية على مواقعنا الإلكترونية وعلى المواقع الإلكترونية لمؤسسات أخرى وقنوات الإعلام الرقمي.كما قد نقيم مدى فعالية رسائلنا التسويقية ورسائل التسويق لموردينا وشركائنا؛/span&gt;</span></li>
            <li ><span>لفهمك بشكل أفضل وتزويدك بالخدمات والاتصالات التسويقية التي تناسب مصالحك واهتماماتك، نجمع بين المعلومات الشخصية التي نحصل عليها لدى شرائك داخل المتجر والمعلومات الشخصية التي نحصل عليها من مواقعنا الإلكترونية وتطبيقاتنا للهاتف المتحرك ومصادر أخرى بما فيها الشركات التابعة المختلفة لمجموعة ماجد&nbsp;الفطيم.;</span></li>
         </ul>
         <p><strong> الاتصال بك والتفاعل معك&nbsp;</strong></p>
         <p>قد نقوم باستخدام معلوماتك الشخصية عند التواصل معك&nbsp;بهدف:</p>
         <ul >
            <li ><span>الاتصال بك بشأن خدماتنا، على سبيل المثال عبر الهاتف أو البريد الإلكتروني أو البريد العادي أو بالرد على مشاركاتك الموجهة إلينا على مواقع التواصل الاجتماعي؛</span></li>
            <li ><span>إدارة حملات الترويج والمسابقات التي تشارك فيها، بما فيها تلك التي نديرها مع موردينا وشركائنا؛</span></li>
            <li ><span>دعوتك لإدارة والمشاركة في استبيانات العملاء والاستطلاعات والأنشطة الأخرى لدراسة السوق التي تقوم بها شركة ماجد&nbsp;الفطيم ومؤسسات أخرى بالنيابة عنا وفي إطار مصالحنا التجارية المشروعة، علينا أن نتمكن من تزويدك بالإجابات المناسبة والإشعارات بشأن خدماتنا المتاحة.</span></li>
         </ul>
         <p><strong> إدارة وتحسين عملياتنا&nbsp;اليومية</strong></p>
         <p>نحلّل المعلومات عن طرق استخدامك لخدماتنا من&nbsp;أجل:</p>
         <ul >
            <li ><span>إدارة وتحسين مواقعنا الإلكترونية وتطبيقاتنا للهاتف المتحرك؛</span></li>
            <li ><span> المساعدة في تطوير وتحسين مجموعة منتجاتنا، وخدماتنا، ومتاجرنا، وأنظمتنا لتقنية المعلومات، وخبرتنا الفنية وطريقة تواصلنا معك؛</span></li>
            <li ><span>اكتشاف عمليات الاحتيال أو الجرائم الأخرى ومنعها .</span></li>
            <p>ومن مصلحتنا التجارية المشروعة استخدام المعلومات المقدمة إلينا لهذا الغرض بحيث يمكننا فهم أي مشاكل في خدماتنا بهدف&nbsp;تحسينها</p>
         </ul>
         <p><strong> مشاركة المعلومات&nbsp;الشخصية</strong></p>
         <p>يجوز مشاركة معلوماتك الشخصية مع جهات خارجية في الظروف&nbsp;التالية:</p>
         <p><strong> الشركات التابعة لشركة&nbsp;ماجد&nbsp;الفطيم</strong></p>
         <p>يجوز مشاركة المعلومات الشخصية التي تجمعها أي شركة من شركاتنا التابعة مع شركاتنا التابعة الأخرى للأغراض المذكورة&nbsp;أعلاه.</p>
         <p><strong> شركاء&nbsp;التجزئة</strong></p>
         <p>يجوز أن نتبادل معلوماتك الشخصية مع شركات أخرى تبيع منتجات من خلال خدماتنا (<strong>"شركاء التجزئة"</strong>). ونتبادل فقط المعلومات الشخصية التي تمكّن شركاء التجزئة لدينا من تقديم خدماتهم. على سبيل المثال، عندما تتسوّق عبر الإنترنت أو داخل المتجر، سنزوّد شريك التجزئة ذا الصلة باسمك وتفاصيل الاتصال بك وعنوانك ليتمكن من تسليمك السلع التي&nbsp;اشتريتها.</p>
         <p><strong> مزوّدو&nbsp;الخدمات</strong></p>
         <p>نعمل مع مزوّدي الخدمات الذين يتم اختيارهم بدقة والذين يؤدون وظائف معينة بالنيابة عنا. ويشمل هؤلاء، على سبيل المثال، الشركات التي تساعدنا على توفير الخدمات التقنية وتخزين البيانات وجمعها وتجهيز المدفوعات وتسليم الطلبيات. ونشارك فقط المعلومات الشخصية التي تمكّن مزوّدي الخدمات لدينا من تقديم&nbsp;خدماتهم.</p>
         <p>ويشغل بعض مزوّدي الخدمات الذين نعمل معهم قنوات الإعلام الرقمي وينشرون الإعلانات الرقمية ذات الصلة لمنتجاتنا وخدماتنا ومنتجات وخدمات المورّدين وشركاء التجزئة لدينا على قنوات الإعلام الرقمي هذه بالنيابة عنا. على سبيل المثال، قد ترى إعلاناً لمنتجاتنا وخدماتنا عند استخدامك موقعاً معيناً للتواصل&nbsp;الاجتماعي.</p>
         <p><strong> مشاركة المعلومات الشخصية مع مؤسسات&nbsp;أخرى</strong></p>
         <p>يجوز لنا مشاركة المعلومات الشخصية مع مؤسسات أخرى في الظروف&nbsp;التالية:</p>
         <ul >
            <li ><span>إذا فرض علينا أي قانون نافذ أو هيئة عامة مشاركة المعلومات الشخصية؛</span></li>
            <li ><span>إذا تعيّن علينا مشاركة المعلومات الشخصية لإثبات أو ممارسة أو الدفاع عن حقوقنا القانونية (ويشمل ذلك توفير معلومات شخصية لأطراف أخرى لأغراض منع عمليات الاحتيال والحدّ من المخاطر الائتمانية)؛</span></li>
            <li ><span>في حال مشاركة المعلومات الشخصية مع مؤسسة نبيعها أو ننقل إليها (أو نتفاوض معها بشأن بيع أو نقل ملكية) أياً من أعمالنا أو حقوقنا أو التزاماتنا بموجب أي اتفاقية قد نبرمها معك بهذا الشأن. في حال سريان عملية نقل الملكية أو البيع، يجوز للمؤسسة التي تحصل على معلوماتك الشخصية استخدام هذه المعلومات بالطريقة نفسها</span></li>
            <li ><span>في حال مشاركة المعلومات الشخصية مع أي مؤسسة ننقل ملكيتنا القانونية إليها ؛ أو</span></li>
            <li ><span>في حال مشاركة المعلومات الشخصية مع أي جهة ننقل أو قد ننقل إليه حقوقنا وواجباتنا بموجب اتفاقيتنا معك</span></li>
         </ul>
         <p><strong> نقل معلوماتك&nbsp;الشخصية</strong></p>
         <p ><span >يجوز نقل معلوماتك الشخصية وتخزينها ومعالجتها في بلد يختلف عن البلد الذي تقيم فيه أو بلد التأسيس للشركة. وفي كلتا الحالتين قد وضعنا ضمانات مناسبة وفقاً للمتطلبات القانونية المطبقة لضمان حماية بياناتك بما&nbsp;يكفي.</span></p>
         <p >&nbsp;</p>
         <p ><span >وفي حال تم نقل معلوماتك الشخصية إلى ولاية قضائية أجنبية، قد تخضع لقوانين تلك الولاية وقد يُطلب إلينا الإفصاح عنها للمحاكم أو السلطات المعنية بتطبيق القوانين أو الهيئات الحكومية في تلك الولاية، ولكن لن نقوم بذلك إلا بموجب القوانين&nbsp;النافذة.</span></p>
         <p><strong> كيف نحمي المعلومات&nbsp;الشخصية؟</strong></p>
         <p>نحتفظ بضوابط مادية وتقنية وتنظيمية لحماية معلوماتك الشخصية من الوصول والاستخدام والتعديل غير المصرّح به ومن إتلاف المعلومات. غير أن المواقع القائمة على شبكة الإنترنت قد لا تكون آمنة تماماً وبالتالي لا نتحمل مسؤولية أي وصول غير مصرّح به أو غير مقصود يخرج عن نطاق&nbsp;سيطرتنا.</p>
         <p><strong> مواقع التواصل&nbsp;الاجتماعي</strong></p>
         <p>نقوم بتشغيل قنوات وصفحات وحسابات على بعض مواقع التواصل الاجتماعي لإطلاع عملائنا على المستجدات ومساعدتهم والتواصل معهم. كما نراقب ونسجّل التعليقات والمشاركات المتعلقة بنا على هذه القنوات من أجل تحسين&nbsp;خدماتنا.</p>
         <p>ولا تتحمل شركة ماجد&nbsp;الفطيم مسؤولية أي معلومات يتم نشرها على هذه المواقع غير المعلومات التي نقوم بنشرها. كما لا نؤيد مواقع التواصل الاجتماعي بحد ذاتها أو أي معلومات يتم نشرها عليها من جانب الجهات&nbsp;الخارجية&lrm;.</p>
         <p><strong> الاحتفاظ&nbsp;بالسجلات</strong></p>
         <p>نحتفظ بالسجلات لمدة مناسبة لـ أ) إدارة المشتريات والحجوزات والعضويات وتوفير المنتجات أو الخدمات الأخرى ذات الصلة التي تتطرّق إليها سياسة الخصوصية هذه، بما في ذلك إطلاعك بصورة دائمة على أنشطتنا التسويقية. ب) اضفاء طابع شخصي على المنتجات والخدمات والاتصالات معك. ج) الامتثال للمتطلبات القانونية المعمول بها في ما يخص الاحتفاظ بالسجلات. وعندما نقرر الفترة التي سنحتفظ خلالها بمعلوماتك الشخصية بعد انتهاء علاقتنا معك، نأخذ في الاعتبار موجباتنا القانونية وتوقعات الجهات&nbsp;الناظمة.</p>
         <p>وإذا رغبت أن تطلب منا التوقف عن استخدام معلوماتك لتقديم الخدمات إليك، اتصل بنا على مكتب التحليلات، لشركة ماجد&nbsp;الفطيم لخدمات الإدارة، ص. ب. 91100، دبي، الإمارات العربية المتحدة. كما يمكنك مراسلتنا على العنوان <a href="mailto:privacy@maf.ae"><span >privacy@maf.ae</span></a>. لكن سنحتفظ بمعلومات التسجيل الخاصة بك ونستخدمها عند الضرورة للامتثال لموجباتنا القانونية وحل النزاعات وتطبيق الاتفاقيات التي&nbsp;نبرمها.</p>
         <p><strong> الرسائل&nbsp;التسويقية</strong></p>
         <p>ستزوّدك كافة الرسائل التسويقية التي نرسلها إليك بطريقة لسحب موافقتك بشأن التسويق في المستقبل. فإذا رغبت في التوقف عن تلقي المواد الترويجية، يمكنك اختيار عدم استلام تلك الرسائل أو تغيير إعدادات حسابك، وسيتم بالتالي شطب اسمك في قوائم&nbsp;التسويق.</p>
         <p>ونرجوا منك أخذ العلم بأنه إذا قمت بإلغاء اشتراكك في خدمة الرسائل التسويقية ستصلك باستمرار رسائل التشغيل والخدمة التي نرسلها إليك بشأن الخدمات أو المنتجات التي اشتريتها منا والاستجابات للاستفسارات الموجهة إلينا، وبأنه يجوز لنا الاحتفاظ بالتفاصيل الخاصة بك على "قائمة منع" كي لا نرسل رسائل تسويقية إليك في&nbsp;المستقبل.</p>
         <p><strong> ملفات تعريف الارتباط والتقنيات&nbsp;المشابهة</strong></p>
         <p>نستخدم ملفات تعريف الارتباط (cookies) والتقنيات المشابهة مثل العلامات والبكسل (tags and pixels) ("ملفات تعريف الارتباط") لتخصيص وتحسين تجربتك كعميل بينما تستخدم مواقعنا الإلكترونية وتطبيقاتنا للهاتف المتحرك، وتزويدك بالإعلانات ذات الصلة عبر الإنترنت. إن ملفات تعريف الارتباط هي عبارة عن ملفات صغيرة للبيانات تسمح لموقع إلكتروني أو لتطبيق للهاتف المتحرك بجمع وتخزين مجموعة من البيانات على جهاز الكمبيوتر المكتبي لديك أو جهاز الكمبيوتر المحمول أو جهاز&nbsp;محمول.</p>
         <p>وتساعدنا ملفات تعريف الارتباط هذه في توفير ميزات ووظائف مهمة على مواقعنا الإلكترونية وتطبيقاتنا للهاتف المتحرك، ونستخدمها لتحسين تجربتك كعميل. على سبيل المثال، نستخدم هذه الملفات للقيام بما&nbsp;يلي:</p>
         <p><strong> تحسين طريقة عمل مواقعنا الإلكترونية وتطبيقاتنا على الهاتف&nbsp;المتحرك</strong></p>
         <p>تسمح لنا ملفات تعريف الارتباط بتحسين طريقة عمل مواقعنا الإلكترونية وتطبيقاتنا على الهاتف المتحرك بحيث يمكننا تخصيص تجربتك والسماح لك باستخدام العديد من ميزاتها المفيدة. على سبيل المثال، نستخدم ملفات تعريف الارتباط لنتذكر تفضيلاتك ومحتويات سلة التسوّق لديك عند عودتك إلى مواقعنا الإلكترونية وتطبيقاتنا للهاتف&nbsp;المتحرك.</p>
         <p>&nbsp;</p>
         <p><strong> تحسين أداء مواقعنا الإلكترونية وتطبيقاتنا على الهاتف&nbsp;المتحرك</strong></p>
         <p>قد تساعدنا ملفات تعريف الارتباط في فهم كيفية استخدام مواقعنا الإلكترونية وتطبيقاتنا للهاتف المتحرك، إذ تفيدنا مثلاً بتلقيك الرسائل عند حدوث خطأ ما بينما تتصفح الموقع أو التطبيق. وتجمع هذه الملفات البيانات المجمعة (المصنّفة) ومجهولة المصدر في&nbsp;الغالب.</p>
         <p><strong>وضع الإعلانات الرقمية ذات&nbsp;الصلة</strong></p>
         <p>نستخدم ملفات تعريف الارتباط لتساعدنا في وضع الإعلانات الرقمية التي نرى أنها الأكثر ملاءمة لك على مواقعنا الإلكترونية والمواقع الإلكترونية لمؤسسات&nbsp;أخرى.</p>
         <p>وغالباً ما تضع مؤسسات أخرى ملفات تعريف الارتباط، التي يتم استخدامها لهذا الغرض، على مواقعنا الإلكترونية ويحصل ذلك دائماً بموافقتنا. وقد تجمع هذه الملفات معلومات عن سلوكك على شبكة الإنترنت على غرار عنوان بروتوكول الإنترنت والموقع الإلكتروني الذي وصلت إلينا من خلاله، ومعلومات عن تاريخ مشترياتك أو محتوى سلة التسوّق لديك. ويعني ذلك أنك قد ترى إعلاناتنا على مواقعنا الإلكترونية وعلى مواقع مؤسسات أخرى. وقد ترى أيضاً إعلانات لمؤسسات أخرى على&nbsp;مواقعنا.</p>
         <p><strong> قياس مدى فعالية رسائلنا التسويقية بما فيها الإعلانات&nbsp;الرقمية</strong></p>
         <p>بوسعنا أن نعرف أنك شاهدت إعلاناً محدداً من خلال ملفات تعريف الارتباط، كما يمكننا معرفة كم مر من الوقت على مشاهدتك له. وتسمح لنا هذه المعلومات بقياس مدى فعالية حملاتنا الإعلانية الرقمية ومراقبة عدد المرات التي ترى فيها أحد&nbsp;الإعلانات.</p>
         <p>كما نستخدم ملفات تعريف الارتباط لقياس مدى فعالية رسائلنا التسويقية، فهي تعلمنا على سبيل المثال بأنك قمت بفتح رسالة إلكترونية تسويقية قد أرسلناها&nbsp;إليك.</p>
         <p><strong> خياراتك في ما يتعلق بملفات تعريف&nbsp;الارتباط</strong></p>
         <p>بوسعك استخدام إعدادات المتصفح لديك لقبول أو رفض ملفات تعريف الارتباط الجديدة ومسح ملفات تعريف الارتباط الحالية. وبإمكانك أيضاً إعداد المتصفح لإخطارك في كل مرة يتم فيها وضع ملفات جديدة لتعريف الارتباط على جهاز الكمبيوتر لديك أو جهاز&nbsp;آخر.</p>
         <p>وإذا اخترت تعطيل بعض هذه الملفات أو كلها، قد لا تتمكن من استخدام منصاتنا بصورة كاملة. على سبيل المثال، قد لا تتمكن من إضافة أصناف إلى سلة التسوّق لديك أو متابعة التسوّق أو استخدام بعض منتجاتنا وخدماتنا التي تلزمك بتسجيل الدخول. وحالما تمنحنا موافقتك على استخدام ملفات تعريف الارتباط، نعمل على تخزينها على جهاز الكمبيوتر أو أجهزة أخرى لديك. وإذا رغبت في سحب موافقتك في أي وقت، سيتعين عليك مسح ملفات تعريف الارتباط، التي وضعناها، بواسطة إعدادات متصفح الإنترنت&nbsp;لديك.</p>
         <p><strong> تجميع البيانات وتحليلاتها ووظائف مواقع التواصل&nbsp;الاجتماعي</strong></p>
         <p>يجوز لنا أن نعمل على تجميع المعلومات الشخصية وإزالة أي عناصر مميزة بهدف تحليل الأنماط وتحسين جهودنا التسويقية والترويجية وتحليل طريقة استخدام المنصات وتحسين المحتوى وعروض المنتجات لدينا وتخصيص محتوى منصاتنا، والتصميم، والمنتجات والخدمات، ويجوز أن نعيّن أطرافاً ثالثة (جهات خارجية) للقيام بذلك نيابةً عنا كما هو مذكور&nbsp;أعلاه.</p>
         <p>كما أننا نجمع معلومات معينة عن الاستخدام مثل عدد الزائرين لمنصاتنا ووتيرة زياراتهم. وقد تشمل هذه المعلومات صفحة الويب التي أتيت منها وصفحة الويب التي انتقلت بعد ذلك إليها ونوع المتصفح الذي تستخدمه وجهازك وعنوان بروتوكول الإنترنت الخاص بك. ونستخدم هذه البيانات فقط في عملية التجميع. وتساعدنا هذه البيانات الجماعية في تحديد حجم استخدام عملائنا لأجزاء من منصاتنا وإجراء البحث بشأن التركيبة الديموغرافية للمستخدمين لدينا واهتماماتهم وسلوكهم لفهمك وخدمتك بشكل&nbsp;أفضل.</p>
         <p>وإذا استخدمت أزراراً على منصاتنا ترتبط بمواقع التواصل الاجتماعي أو مواقع مماثلة (على سبيل المثال، زر الإعجاب Like و/أو زر المشاركة Share)، قد تتم إعادة إرسال المحتوى من منصاتنا إلى ذلك الموقع الإلكتروني الآخر أو الخدمة الأخرى وقد يصبح ظاهراً بصورة خاصة أو علنية بحسب إعدادات الخصوصية لديك (قد يراه على سبيل المثال الأصدقاء والمتابعون فقط أو أي شخص يمكنه الوصول إلى صفحتك الشخصية&nbsp;عموماً).</p>
         <p><strong>&nbsp;حقوقك</strong></p>
         <p>يحق لك الاطّلاع على أو الوصول إلى المعلومات الشخصية التي نحتفظ بها عنك. وللحصول على نسخة، نرجوا منك مراسلة مكتب التحليلات، لشركة ماجد&nbsp;الفطيم لخدمات الإدارة، ص. ب. 91100، دبي، الإمارات العربية المتحدة. كما يمكنك مراسلتنا على البريد الإلكتروني <a href="mailto:privacy@maf.ae"><span >privacy@maf.ae</span></a>.</p>
         <p>بالإضافة إلى ذلك، لديك حقوق معينة فيما يتعلق بمعلوماتك الشخصية، تخضع للقانون المحلي. وتشمل الحقوق&nbsp;التالية:</p>
         <ul >
            <li ><span>تصحيح المعلومات التي نحتفظ بها عنك؛</span></li>
            <li ><span>حذف معلوماتك الشخصية؛</span></li>
            <li ><span>تقييد طريقة استخدامنا لمعلوماتك الشخصية؛</span></li>
            <li ><span>الاعتراض على طريقة استخدامنا لمعلوماتك الشخصية؛</span></li>
            <li ><span>سحب أي موافقات كنت قد منحتها لنا لاستخدام معلوماتك الشخصية؛</span></li>
            <li ><span>استلام معلوماتك الشخصية بصيغة إلكترونية صالحة للاستعمال ونقلها إلى طرف ثالث (الحق في إمكانية نقل البيانات)؛</span></li>
            <li ><span>وتقديم شكوى لدى هيئة حماية البيانات المحلية.</span></li>
         </ul>
         <p>وإذا رغبت في مناقشة أو ممارسة هذه الحقوق، الرجاء الاتصال بنا على العنوان التفصيلي&nbsp;أدناه.</p>
         <p>وإذا وجدت أن أي معلومة من المعلومات التي نحتفظ بها عنك غير صحيحة أو قديمة، الرجاء مراسلتنا على العنوان الوارد أعلاه لإبلاغنا بذلك وسنتخذ الخطوات المناسبة لتعديل&nbsp;سجلاتنا.</p>
         <p>كما نود إحاطتك علماً بأننا قد نطلب منك توفير معلومات تعريفية إضافية لنتمكن من تلبية&nbsp;طلباتك.</p>
         <p>&nbsp;</p>
         <p><strong> اعتبارات&nbsp;الخصوصية</strong></p>
         <p>إذا كانت لديك أي مخاوف بشأن امتثالنا لقوانين الخصوصية النافذة أو لسياسة الخصوصية هذه، الرجاء مراسلة مكتب الامتثال في ، شركة ماجد&nbsp;الفطيم لخدمات الإدارة، ص.ب. 91100، أبوظبي، الإمارات العربية المتحدة أو إرسال رسالة بريد إلكتروني على <a href="mailto:privacy@maf.ae"><span >privacy@maf.ae</span></a>. وسنسعى إلى الرد على مخاوفك في أقرب وقت&nbsp;ممكن.</p>
         <p><strong> الإشعار بالتعديلات في سياسة الخصوصية&nbsp;هذه</strong></p>
         <p>إننا نقوم بتحديث سياسة الخصوصية هذه عند الحاجة وإجراء تحديثات في منصاتنا. وإذا كنا نريد استخدام معلوماتك الشخصية بطريقة مختلفة عن الطريقة المعلن عنها في وقت جمع المعلومات، فسنشعرك بذلك. وستصبح كافة التعديلات نافذة لدى نشرها ما لم يتم الإشارة إلى خلاف ذلك. الرجاء مراجعة سياسة الخصوصية هذه دورياً للاطلاع على آخر التعديلات&nbsp;فيها.</p>
         <p><strong>&nbsp;الروابط</strong></p>
         <p>قد تحتوي مواقعنا الإلكترونية أو تطبيقاتنا على الهاتف المتحرك على روابط مواقع إلكترونية أخرى تشغلها مؤسسات أخرى لديها سياسات الخصوصية الخاصة بها. وفي هذه الحالة، الرجاء التأكد من أنك اطّلعت بدقة على الشروط والأحكام وسياسة الخصوصية قبل تقديم أي معلومات شخصية على أحد المواقع الإلكترونية لأننا لا نقبل بتحمل أي مسؤولية أو التزام لجهة المواقع الإلكترونية لمؤسسات&nbsp;أخرى.</p>
         <p><strong>&nbsp;<span >الأطفال</span></strong></p>
         <p>لا تستهدف منصاتنا التواصل مع الأطفال دون عمر ال 13 عام، إن تقديمك لبيانات عن أي شخص دون عمر ال13 عام يعني موافقتك كولي أمر و/ أو وصي قانوني لجمع، استخدام أو معالجة المعلومات الخاصة بالشخص المشار إليه كما تنص سياسة حماية&nbsp;الخصوصية.</p>
         <br/>
      </ul>
   </div>
</div>
)}
    </>
  );
};
export default PP;
