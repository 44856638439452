import { useState, useEffect } from "react";
import leftImg from "../../assets/images/big_c.webp";
import arabicC from "../../assets/images/arabic_bigc.png";
import { withNamespaces } from "react-i18next";

import i18n from "../../i18n";
import ReactSnapPixel from 'react-snapchat-pixel';

const Manifesto = ({t}) => {
  const [lang, setLang] = useState("");
  useEffect(() => {
    setLang(localStorage.getItem("i18nextLng"));

}, [t('current_locale')]);
useEffect(() => {
  const userIdentification = { user_email: 'some@email.com' }; // optional
  const options = {
      debug: false, 		// enable logs
  };
  ReactSnapPixel.init('0b100a5f-a529-4761-9b8e-ec3387bc1625', userIdentification, options);
   
  ReactSnapPixel.pageView(); 	

}, [])
  document.body.classList.remove("home");

  return (
    <>
      <div className="row align-items-center justify-content-center my-3">
        {/* <h2>{t('vote')}</h2> */}
        <div className="col-md-6 mb-3">
          {lang == "en" && (
            <img src={leftImg} alt="logo" className="img-fluid" />
          )}
          {lang == "ar" && (
            <img src={arabicC} alt="logo" className="img-fluid" />
          )}
        </div>
        <div className="col-md-6 text-start">
          {lang == "en" && (
            <span>
              <h2 className="text-md-start">It’s your space.</h2>
              <p>
                <b>How do you create the best malls in the world? </b>
              </p>

              <p>
                Well, we’re still trying to figure that out.<br/> But if there’s one
                thing we know for sure,<br/> it’s that it can’t be done from inside
                the walls of our boardroom or our headquarters.
              </p>
              <p>
                So, we decided to move our boardroom here to create a little
                more ‘room’ in the boardroom for the thousands of other opinions
                that really matter. <br/>Yours – our customers’.
              </p>
              <p>
                Imagine a mall built on your brilliant ideas. Maybe the best
                malls in the world are the ones that care most about their
                customers’ thoughts, needs, dreams and aspirations.
              </p>
              <p>
                So, if you want more fun, films, fashion or fancy ice cream
                flavours, pop-up stores popping up more often, food that’s
                locally grown or globally found, plus drone delivery, robot
                chefs, or maybe cooking classes so you can become a chef,<br/> then
                vote now. 
              </p>
              <p>
                After all, it’s your space. So come on, tell us. What would make
                it feel more like your <br/> perfect place?
              </p>

              <p>City Centre</p>
              <p>It’s your space.</p>
            </span>
          )}

          {lang == "ar" && (
            <span className="text-end" dir="rtl">
              {/* <h2 className="text-md-end">إنها مساحتك</h2> */}
              <p>
                <b>كيف نبتكر أفضل مراكز التسوّق في العالم؟ </b>
              </p>

              <p>
                الجميع يبحث عن الأفضل، بما في ذلك نحن في سيتي سنتر...  نجتمع،
                نفكر ونقرر. لكننا اكتشفنا أن الوصول إلى الأفضل يحتاج  مشاركة كل
                الأطراف، نحن وأنتم...  لذا، قرّرنا أن نوسّع اجتماعاتنا وننقلها
                إلى هذه الطاولة،  لنتعرّف على آلاف الآراء القيّمة من زوّارنا،
                لأنهم شركاؤنا في نجاح وجهات سيتي سنتر.  تخيّلوا، مركزاً تجارياً
                مصمّماً حسب أفكاركم  واهتماماتكم وأمنياتكم. بهذه الطريقة نضمن 
                أن مراكز التسوّق التي نديرها هي الأفضل.  لذا، إذا كنتم ترغبون
                بالمزيد من خيارات الترفيه، الأفلام، الأزياء،  نكهات الآيس كريم
                الفاخرة، أو تحبون أن تكون منصات البيع  متواجدة أكثر، أو تفضّلون
                المأكولات المحلية عن الأصناف العالمية،  أو متشوّقون لاستلام
                طلباتكم عبر طائرة درونز وتذوّق أطباق روبوت شيف  أو لحضور دورات
                الطهي التعليمية... ما عليكم سوى التصويت الآن.  لأن هذه الوجهة هي
                مكانكَ/مكانكِ بامتياز دعونا نعرف ما يجعلها مثالية لتطلعاتكم.
              </p>
            </span>
          )}
        </div>
      </div>
    </>
  );
};
export default withNamespaces()(Manifesto);
