import { useState, useEffect } from "react";
import i18n from "../i18n";
import ReactSnapPixel from "react-snapchat-pixel";

const TC = () => {
  const [lang, setLang] = useState("");
  useEffect(() => {
    setLang(localStorage.getItem("i18nextLng"));
    const enGb = localStorage.getItem("i18nextLng");
    if (enGb === null) {
      setLang("en");
    } else if (enGb === "en-GB" || enGb === "en-US") {
      setLang("en");
    }

    i18n.on("languageChanged init", () => {
      setLang(localStorage.getItem("i18nextLng"));
      const enGb = localStorage.getItem("i18nextLng");
      if (enGb === null) {
        setLang("en");
      } else if (enGb === "en-GB" || enGb === "en-US") {
        setLang("en");
      }
    });
  }, []);
  useEffect(() => {
    const userIdentification = { user_email: "some@email.com" }; // optional
    const options = {
      debug: false, // enable logs
    };
    ReactSnapPixel.init(
      "0b100a5f-a529-4761-9b8e-ec3387bc1625",
      userIdentification,
      options
    );

    ReactSnapPixel.pageView();
  }, []);
  document.body.classList.remove("home");

  return (
    <>
      {lang == "en" && (
        <>
          <p className="mt-5">
            <strong>Majid Al Futtaim Malls </strong>
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <p>
            <strong>BIG C VOTE</strong>
          </p>
          <p>
            <strong>&nbsp;</strong>
          </p>
          <div className="text-start">
            <ol>
              <li>
                The voting activation will be running from 19<sup>th</sup> May
                till 30<sup>th</sup> June 2022
              </li>
              <li>
                Voting platforms: A microsite that is available on all City
                Centre websites and a physical voting activation will be located
                at City Centre Deira from 19<sup>th</sup> till 27<sup>th</sup>{" "}
                May 2022 and City Centre Al Zahia from 2<sup>nd</sup> June till
                12<sup>th</sup> June 2022
              </li>
              <li>Any customer can vote once using their credentials</li>
              <li>
                Any customer who votes and lives in the UAE, will get the chance
                to win 50,000 SHARE POINTS (which is equivalent to AED 5,000)
              </li>
              <li>
                2 winners will be selected to win 50,000 SHARE points each
                (100,000 SHARE points in total, which is equivalent to AED
                10,000)
              </li>
              <li>
                Last day of voting is 30<sup>th</sup> June 2022, at 11:59PM
              </li>
              <li>Draw will occur twice</li>
              <li>Dates of the draws 16 June and 11 July 2022</li>
              <li>Total number of winners selected will be 2 winners</li>
              <li>
                Draw date and time is subject to change with or without notice
              </li>
              <li>
                The winner will be notified on the mobile number provided upon
                registration
              </li>
              <li>
                The winner is required to present the valid identification
                (passport copy and/or national identity card) to collect the
                prize
              </li>
              <li>
                All prizes to be collected within 90 days of the announcement of
                the winner
              </li>
              <li>
                The prizes will be in the form of SHARE points for UAE customers
              </li>
              <li>
                DFRE/DTCM, Sharjah Economic Department &amp; Sharjah Chamber of
                Commerce staff and advertising agencies are not eligible to
                participate in this promotion
              </li>
              <li>
                Owners and employees of the Centre&rsquo;s outlets, Majid Al
                Futtaim Group of Companies, their suppliers and their immediate
                families are not eligible to participate in the voting
                activation
              </li>
              <li>
                Participation in the draw and campaign constitutes permission
                for Majid Al Futtaim to use the participant&rsquo;s personal
                information that is provided for publication and marketing
                purposes, without any liability whatsoever.
              </li>
              <li>
                The decision of Majid Al Futtaim on all aspects will be final
                and binding. No queries or claims will be entertained
              </li>
              <li>Prizes cannot be exchanged for cash or refunded</li>
              <li>Other Terms and Conditions apply</li>
            </ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </>
      )}
      {lang == "ar" && (
        <>
          <p className="mt-5">مراكز مجموعة ماجد الفطيم</p>
          <p>التصويت الكبير</p>
          <p>&nbsp;</p>
          <div className="text-end" dir="rtl">
            <ol>
              <li>تسري فعالية التصويت من 19 مايو إلى 30 يونيو 2022</li>
              <li>
                منصات التصويت: سيتوفر موقع إلكتروني مصغر على جميع مواقع سيتي
                سنتر على الإنترنت إضافة إلى فعّالية التصويت الفعلي في سيتي سنتر
                ديرة من 19 إلى 27 مايو 2022 وسيتي سنتر الزاهية من 2 يونيو حتى 12
                يونيو 2022
              </li>
              <li>يمكن لأي عميل التصويت مرة واحدة باستخدام بياناته الشخصية</li>
              <li>
                أي عميل يشارك في التصويت ويقيم في الإمارات العربية المتحدة، يحصل
                على فرصة ربح 50,000 نقطة "شير" (أي ما يعادل 5,000 درهم)
              </li>
              <li>
                يتم اختيار فائزين اثنين لربح 50,000 نقطة "شير" (إجمالي الجائزتين
                100,000 نقطة "شير" في هذه الفعّالية، أي ما يعادل 10,000 درهم)
              </li>
              <li>آخر يوم للتصويت هو 30 يونيو 2022 الساعة 11:59 مساءً</li>
              <li>يتم السحب على مرتين</li>
              <li>تواريخ السحوبات في 16 يونيو و 11 يوليو 2022</li>
              <li>إجمالي عدد الفائزين الذين سيتم اختيارهم هو 2 فقط</li>
              <li>تواريخ وأوقات السحب عرضة للتغيير مع أو بدون إشعار</li>
              <li>
                يتم إخطار الفائز على رقم الهاتف المحمول المقدم عند التسجيل
              </li>
              <li>
                يُطلب من الفائز تقديم وثيقة تعريف سارية المفعول (نسخة من جواز
                السفر و/أو بطاقة الهوية) لاستلام الجائزة
              </li>
              <li>يجب استلام جميع الجوائز خلال 90 يوماً من إعلان الفائز</li>
              <li>
                ستكون الجوائز على شكل نقاط "شير" لعملاء الإمارات العربية المتحدة
              </li>
              <li>
                لا يحق لموظفي دائرة السياحة والتسويق التجاري بدبي ومؤسسة دبي
                للمهرجانات والتجزئة وموظفي الدائرة الاقتصادية ودائرة الشارقة
                الاقتصادية وغرفة تجارة الشارقة ووكالات الإعلان المشاركة في هذا
                العرض الترويجي
              </li>
              <li>
                لا يحق لموظفي ومالكي المحلات التجارية ومجموعة شركات ماجد الفطيم
                ومورديهم والمستأجرين والموظفين وأسرهم المباشرة المشاركة في هذه
                الحملة الترويجية
              </li>
              <li>
                تعتبر المشاركة في السحب والحملة إذناً لشركة ماجد الفطيم لاستخدام
                المعلومات الشخصية للمشارك التي يتم تقديمها لأغراض النشر
                والتسويق، دون أي مسؤولية من أي نوع
              </li>
              <li>
                يعتبر قرار مجموعة ماجد الفطيم في جميع الجوانب نهائياً وملزماً.
                لن يتم قبول أي استفسارات أو مطالبات
              </li>
              <li>لا يُمكن استبدال الجوائز بقيمة نقدية أو استعادة قيمتها</li>
              <li>تطبّق شروط وأحكام أخرى</li>
            </ol>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
          </div>
        </>
      )}
    </>
  );
};
export default TC;
