import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import "./i18n";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
<BrowserRouter>  
<App />
</BrowserRouter>,
document.getElementById('root'));
